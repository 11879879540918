import React, { Component } from "react";
import { updateNotification } from "../../api/resident";
import SuccessPage from "./SuccessPage";
import SubscribeFormDesign from "./SubscribeFormDesign";

class SubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      resident_id: 0,
      checked: null,
      submit: false,
    };
  }
  componentDidMount() {
    this.fetchResidentData(this.props.data);
  }

  fetchResidentData = ({ id, send_notification }) => {
    this.setState({
      resident_id: id,
      checked: send_notification ? true : false,
    });
  };

  handleChange = (e) => {
    this.setState({ checked: e.target.checked });
  };

  onSubmit = () => {
    const { resident_id, checked } = this.state;
    let body = { send_notification: checked };
    updateNotification(resident_id, body);
    this.setState({
      submit: true,
    });
  };

  render() {
    const { handleChange, onSubmit } = this;
    const { checked, submit } = this.state;
    const { data } = this.props;
    const SubscribeFormDesignProps = { handleChange, onSubmit, checked, data };
    return (
      <React.Fragment>
        {submit && submit ? (
          <SuccessPage />
        ) : (
          <SubscribeFormDesign {...SubscribeFormDesignProps} />
        )}
      </React.Fragment>
    );
  }
}
export default SubscribeForm;
