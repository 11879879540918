import React from "react";
import {useMediaQuery} from "@material-ui/core";
import {location, calendar, home} from "react-icons-kit/entypo";
import {withBaseIcon} from "react-icons-kit";
import {withStyles} from "@material-ui/styles";
import {presence} from "../../helpers";
import moment from "moment";

const styles = () => ({
  animatedBtn: {
    width: "40%",
    border: "1px solid #5575E5",
    padding: "15px 5px",
    borderRadius: "8px",
    textDecoration: "none",
    color: "#ffff",
    cursor: "pointer",
    background: "#5575E5",
    "&:hover": {
      border: "2px solid #5575E5",
    },
  },
});
const SideIconContainer = withBaseIcon();

const RightCard = (props) => {
  const matches = useMediaQuery("(max-width:600px)");
  const Location = () => (
    <SideIconContainer
      icon={location}
      size={matches ? "25px" : "30px"}
      style={{color: "#77838F"}}
    />
  );
  const Calendar = () => (
    <SideIconContainer
      icon={calendar}
      size={matches ? "25px" : "30px"}
      style={{color: "#77838F"}}
    />
  );
  const Home = () => (
    <SideIconContainer
      icon={home}
      size={matches ? "25px" : "30px"}
      style={{color: "#77838F"}}
    />
  );
  const {
    data: {name, invite},
    condo,
  } = props;

  var {invitor, preferred_time} = invite || {};
  invitor = invitor || {};
  return (
    <div className="qr-innercontainer1">
      <div className="qr-icons">
        <div>
          <Location />
          <p>{presence(condo.condoName)}</p>
        </div>

        <div>
          <Home />
          <p>{presence(invitor.unit_number)}</p>
        </div>

        <div>
          <Calendar />
          <p>{moment(preferred_time).format("DD MMM, ddd hh:mm A")}</p>
        </div>
      </div>
      <div>
        <p className="para-head">
          Dear <span style={{textTransform: "capitalize"}}>{name},</span>
        </p>
        <p
          className="para"
          style={{
            color: "#77838F",
            margin: "0px 15px 10px",
          }}
        >
          {"You have been invited by"}
          <b style={{textTransform: "capitalize"}}> {invitor.name}</b> to
          his/her residence at the below address.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          margin: "20px 10px",
        }}
      >
        <a
          href={condo.location}
          target="_ blank"
          className={props.classes.animatedBtn}
          rel="noopener noreferrer"
        >
          Get Direction
        </a>
      </div>
    </div>
  );
};

export default withStyles(styles)(RightCard);
