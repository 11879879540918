import React, { Component } from "react";
import QRCode from "qrcode.react";
import { withBaseIcon } from "react-icons-kit";
import { phone } from "react-icons-kit/icomoon/phone";
import { TimerIcon, ExpiryIcon } from "../../components/SuccessIcons";
import { color, devModeEnabled } from "../../config";
const SideIconContainer = withBaseIcon();

class LeftCard extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0,
      time: 0,
    };
  }

  componentDidMount = () => {
    const { visiting_time } = this.props.data.invite;
    this.getTimeDifference(visiting_time);
    this.timer = setInterval(() => this.getTimeDifference(visiting_time), 1000);
  };

  getTimeDifference = (visiting_time) => {
    const time = Date.parse(visiting_time) - Date.parse(new Date());
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    this.setState({ days, hours, minutes, seconds, time });
    if (time <= 0) {
      clearInterval(this.timer);
      this.timer = null;
      this.props.showQRPage();
    }
  };

  render() {
    const { days, minutes, seconds, hours } = this.state;
    const {
      matches,
      data: { invite, id },
      enabled,
    } = this.props;

    if ((enabled || devModeEnabled) && invite.status === "invited") {
      return (
        <React.Fragment>
          <QRCode
            value={`{"id":"${id}","type":"invites"}`}
            renderAs="svg"
            size={280}
            style={{
              padding: "20px 10px",
            }}
          />
          <p
            style={{
              textAlign: "center",
              margin: "0px",
              padding: "20px 10px",
            }}
          >
            Kindly scan this code in apartment gate to generate your entry pass
          </p>
        </React.Fragment>
      );
    }

    if (!enabled && invite.status === "invited") {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <TimerIcon />

          <React.Fragment>
            <p className="content-title" style={{ marginTop: "0px" }}>
              This QR will activate in
            </p>
            <h1
              style={{
                color: color,
                marginTop: "0px",
                fontWeight: "bolder",
              }}
            >
              <div
                className="qr-gen-container"
                style={{ backgroundColor: "#ffffff", width: "100%" }}
              >
                {days !== 0 && (
                  <React.Fragment>
                    <div>
                      {days < 10 ? "0" + days : days}
                      <p className="timer-label">Days </p>
                    </div>
                    <div>:</div>
                  </React.Fragment>
                )}
                {hours !== 0 ? (
                  <React.Fragment>
                    <div>
                      {hours < 10 ? "0" + hours : hours}
                      <p className="timer-label">Hours</p>
                    </div>
                    <div>:</div>
                  </React.Fragment>
                ) : days !== 0 ? (
                  <React.Fragment>
                    <div>
                      00<p className="timer-label">Hours</p>
                    </div>
                    <div>:</div>
                  </React.Fragment>
                ) : (
                  ""
                )}
                {minutes !== 0 ? (
                  <React.Fragment>
                    <div>
                      {minutes < 10 ? "0" + minutes : minutes}
                      <p className="timer-label">Minutes</p>
                    </div>
                    <div>:</div>
                  </React.Fragment>
                ) : hours !== 0 ? (
                  <React.Fragment>
                    <div>
                      00<p className="timer-label">Minutes</p>
                    </div>
                    <div>:</div>
                  </React.Fragment>
                ) : (
                  ""
                )}
                {seconds !== 0 ? (
                  <div>
                    {seconds < 10 ? "0" + seconds : seconds}
                    <p className="timer-label">Seconds</p>
                  </div>
                ) : (
                  <div>
                    00<p className="timer-label">Seconds</p>
                  </div>
                )}
              </div>
            </h1>
          </React.Fragment>
        </div>
      );
    }

    if (invite.status === "expired") {
      return (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <ExpiryIcon />

          <p
            className="content-title"
            style={{ marginTop: "0px", color: "red", fontSize: "22px" }}
          >
            Invite Expired
          </p>
          <span style={{ marginBottom: "20px" }}>
            <SideIconContainer
              icon={phone}
              size={matches ? "15px" : "30px"}
              style={{ color: "#77838F" }}
            />
            Contact Resident
          </span>
        </div>
      );
    }

    return <p>Invalid</p>;
  }
}
export default LeftCard;
