import React from 'react';
import Head from '../../components/Head';
import RightCard from './rightCard';
import LeftCard from './leftCard';
import { useMediaQuery } from '@material-ui/core';

const Invited = (props) => {
  const matches = useMediaQuery('(max-width:600px)');
  return (
    <React.Fragment>
      <Head
        condo={props.condo.condoName || props.data.condo.name}
        subHeading={'Contrator Registration'}
      />
      <div className="qr-root" style={{ backgroundColor: '#ffffff' }}>
        <div className="qr-container">
          <div className="qr-innercontainer">
            <LeftCard {...props} matches={matches} />
          </div>
          <RightCard {...props} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Invited;
