import React, {Component} from "react";
import {verifyResident, verifyOtp, createInvite} from "../../api/webInvite";
import InviteOTP from "./inviteOtp";
import InvitePage from "./invitePage";
import ResidentForm from "./residentForm";
import SuccessPage from "./successPage";

class Invitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: {
        visible: false,
        message: "",
        variant: "",
      },
      auth_key: "test",
      otpPage: false,
      invitePage: false,
      successPage: false,
      disableBtn: false,
    };
  }

  onClose = () => {
    this.setState({
      snackbar: {
        visible: false,
      },
    });
  };

  residentFormSubmit = (name, phone) => {
    const requestParams = {name, phone};

    this.setState({disableBtn: true, phone: phone});
    verifyResident(requestParams)
      .then((data) => {
        let display = data.auth_key ? "invitePage" : "otpPage";
        this.setState({
          snackbar: {
            visible: true,
            message: "Login Successfully..!!!",
            variant: "success",
          },
          auth_key: data.auth_key,
          [display]: true,
          disableBtn: false,
        });
      })
      .catch((err) => {
        let msg =
          err[0] === 404
            ? "This is not a Registered Number"
            : "Something Not Right";

        this.setState({
          snackbar: {
            visible: true,
            message: msg,
            variant: "warning",
          },
          disableBtn: false,
        });
      });
  };

  otpSubmit = (otp) => {
    let requestParams = {
      otp_token: otp,
      phone: this.state.phone,
    };

    this.setState({disableBtn: true});
    verifyOtp(requestParams)
      .then((data) => {
        if (data.auth_key) {
          this.setState({
            invitePage: true,
            disableBtn: false,
            auth_key: data.auth_key,
            snackbar: {
              visible: true,
              message: "OTP Verified",
              variant: "success",
            },
          });
        }
      })
      .catch((err) => {
        let msg =
          err[0] === 401 ? "Please Enter Valid OTP...!" : "Something Not Right";

        this.setState({
          snackbar: {
            visible: true,
            message: msg,
            variant: "warning",
          },
          disableBtn: false,
        });
      });
  };

  inviteSubmit = (data) => {
    const {auth_key} = this.state;

    if (!auth_key) {
      console.log("No Auth Key Present");
      return;
    }

    const requestParams = {
      auth_key: auth_key,
      invites: data,
    };

    this.setState({disableBtn: true});

    createInvite(requestParams)
      .then(({message}) => {
        this.setState({
          snackbar: {
            visible: true,
            message: "Invite Created Successfully",
            variant: "success",
          },
          successPage: true,
          disableBtn: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          snackbar: {
            visible: true,
            message: "Something is not right..!",
            variant: "error",
          },
          disableBtn: false,
        });
      });
  };

  render() {
    const {condos} = this.props;
    let {
      phone,
      otpPage,
      invitePage,
      successPage,
      snackbar,
      disableBtn,
      auth_key,
    } = this.state;

    const {onClose, residentFormSubmit, otpSubmit, inviteSubmit} = this;

    if (successPage) {
      return (
        <SuccessPage condos={condos} snackbar={snackbar} onClose={onClose} />
      );
    }

    if (invitePage) {
      return (
        <InvitePage
          condos={condos}
          snackbar={snackbar}
          disableBtn={disableBtn}
          onClose={onClose}
          formSubmit={inviteSubmit}
          auth_key={auth_key}
        />
      );
    }

    if (otpPage) {
      return (
        <InviteOTP
          condos={condos}
          phone={phone}
          snackbar={snackbar}
          disableBtn={disableBtn}
          onClose={onClose}
          sendOtp={residentFormSubmit}
          formSubmit={otpSubmit}
        />
      );
    }

    return (
      <ResidentForm
        condos={condos}
        snackbar={snackbar}
        disableBtn={disableBtn}
        onClose={onClose}
        formSubmit={residentFormSubmit}
      />
    );
  }
}

export default Invitation;
