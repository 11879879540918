import React from 'react';
import { Head, SnackBar } from '../../components';
import { InviteSuccess } from '../../assets/icons';

const SuccessPage = (props) => {
  const {
    condos,
    snackbar: { visible, variant, message },
    onClose,
  } = props;

  return (
    <React.Fragment>
      <Head condo={condos.condoName} subHeading={'Contrator Registration'} />
      <div className="main-root">
        <div className="main-container">
          <div style={{ textAlign: 'center', width: '100%' }}>
            <p style={{ fontSize: 26, margin: '30px 0px' }}>
              Invite Created Successfully
            </p>
            <InviteSuccess />
            <p>
              SMS is sent to your visitors with a link for the Smart QR Code.{' '}
            </p>
            <p>
              The Smart QR Code can be used to quick check-in at your condominum
              entry gates
            </p>
          </div>
        </div>
      </div>
      {visible && (
        <SnackBar
          triggerOpen={visible}
          message={message}
          variant={variant}
          onClose={onClose}
        />
      )}
    </React.Fragment>
  );
};

export default SuccessPage;
