import React, { Component } from "react";
import "../../assets/css/common.css";
import { SimpleTextField, CustomFormControl } from "../../components/FormField";
import SubmitButton from "../../components/SubmitButton";
import { createVisit, searchUnitData } from "../../api/visit";
import { getCookie } from "../../helpers";
import SuccessPage from "../../components/SuccessPage";
import Asynchronous from "../../components/AutoComplete";
import { Success } from "../../components/SuccessIcons";
import {
  AirConService,
  AirConServiceFill,
  Maintenance,
  MaintenanceFill,
  Electrical,
  ElectricalFill,
  Plumbing,
  PlumbingFill,
  HouseKeep,
  HouseKeepFill,
  Others,
  OthersFill,
} from "../../components/IndexIcon";
import workflow from "../../workflow.json";
import Head from "../../components/Head";
import PageNotFound from "../../components/ErrorPage";

class Contractor extends Component {
  constructor(props) {
    super();
    this.state = {
      loader: true,
      message: "",
      data: {
        name: "",
        phone: "",
        mode_of_entry: "car",
        number_plate: "",
        nric: "",
        company: "",
        type: "Air-Con",
        block: "",
        sub_type: "",
        temperature: "",
      },
      error: false,
      blockList: [],
      loading: false,
      success: false,
      errorMessage: false,
      nricPrier: "XXXXX",
      homePage: false,
      temperatureShow:
        workflow[`${props.about.aliasName}`]["fields"]["temperature"],
    };
  }

  handleChange = (name, value) => {
    let { data, nricPrier, error } = this.state;

    if (name === "nric") {
      data[name] = data[name]
        ? value.slice(0, 9)
        : `${nricPrier}${value.slice(0, 4)}`;
      if (data[name].length < 6) {
        data[name] = "";
      }
    } else if (name === "phone") {
      let phoneno = /^[0-9]{7,8}\d*$/;
      if (value.match(phoneno)) {
        data[name] = value;
        error = false;
      } else {
        data[name] = value;
        error = true;
      }
    } else {
      data[name] = value;
    }

    this.setState({ data, error }, () => {
      this.state.data.block && this.setState({ errorMessage: false });
    });
  };

  componentDidMount = () => {
    const { blockList } = this.props.about;
    !blockList.length
      ? searchUnitData("", getCookie("vms_token"))
          .then(({ data }) => {
            let units = data.map((value) => ({
              label: value.unit_number,
              value: value.unit_number,
            }));
            this.setState({ blockList: units, loader: false });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loader: true, homePage: true });
          })
      : this.setState({ blockList: blockList, loader: false });
  };

  requestParams = () => {
    const { data } = this.state;
    return {
      visits: {
        ...data,
        visitor_type: "contractor",
        mode_of_entry:
          data.mode_of_entry === "bike" ? "car" : data.mode_of_entry,
        type: data.sub_type || data.type,
      },
    };
  };

  formSubmit = () => {
    if (this.state.data.phone.length !== 8) {
      return document.getElementById("phone").focus();
    }
    this.setState({ loading: true });
    createVisit(this.requestParams(), getCookie("vms_token"))
      .then(() => {
        this.setState({ loading: false, success: true });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  render() {
    const styles = {
      text: {
        fontStyle: "normal",
        fontSize: "18px",
        lineHeight: "22px",
      },
      input: {
        width: 300,
        marginBottom: 15,
      },
    };
    const { handleChange } = this;
    const types = [
      {
        name: "Air-Con",
        image: <AirConService />,
        imageFill: <AirConServiceFill />,
      },
      {
        name: "Electrical",
        image: <Electrical />,
        imageFill: <ElectricalFill />,
      },
      {
        name: "Maintenance",
        image: <Maintenance />,
        imageFill: <MaintenanceFill />,
      },
      { name: "Plumbing", image: <Plumbing />, imageFill: <PlumbingFill /> },
      {
        name: "House Keeping",
        image: <HouseKeep />,
        imageFill: <HouseKeepFill />,
      },
      { name: "Others", image: <Others />, imageFill: <OthersFill /> },
    ];
    const {
      data: {
        name,
        phone,
        mode_of_entry,
        number_plate,
        nric,
        company,
        type,
        sub_type,
        temperature,
      },
      loader,
      success,
      loading,
      blockList,
      errorMessage,
      error,
      homePage,
      temperatureShow,
    } = this.state;
    if (homePage) {
      return <PageNotFound />;
    }

    if (success) {
      return (
        <SuccessPage
          content={
            workflow[`${this.props.about.aliasName}`]["contractor"][
              "success_message"
            ]
          }
          head={
            workflow[`${this.props.about.aliasName}`]["contractor"]["title"]
          }
          compo={<Success />}
          subHeading={"Contractor Self-Checkin Service"}
          condos={this.props.about}
        />
      );
    }
    if (loader) {
      return <div id="loader" />;
    }

    return (
      <React.Fragment>
        <Head
          condo={this.props.about.condoName}
          subHeading={"Contractor Self-Checkin Service"}
        />
        <div className="main-root">
          <div className="main-container">
            <div className="head-content-title">
              <p style={{ margin: "5px 0px" }}>Hello there,</p>
              <p style={{ margin: "5px 0px" }}>Kindly fill your details</p>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.formSubmit();
              }}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="form-container">
                <p
                  className="custom-text"
                  style={{ margin: 15, textAlign: "start" }}
                >
                  Contractor type :
                </p>

                {types.map((value, i) => (
                  <React.Fragment>
                    <div
                      className="img-container"
                      onClick={(e) => handleChange("type", value["name"])}
                    >
                      {type === value["name"]
                        ? value["imageFill"]
                        : value["image"]}
                      <p
                        style={{
                          margin: "0px",
                          fontSize: "14px",
                          height: "17px",
                        }}
                      >
                        {value.name}
                      </p>
                    </div>
                  </React.Fragment>
                ))}
                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  {type === "Others" && (
                    <SimpleTextField
                      id="sub_type"
                      label="Contractor Type"
                      defaultValue={sub_type}
                      onChange={(e) => handleChange("sub_type", e.target.value)}
                      required
                    />
                  )}
                </div>
                <p
                  className="custom-text"
                  style={{ margin: 15, textAlign: "start" }}
                >
                  Contractor Details :
                </p>
                <SimpleTextField
                  id="name"
                  label="Name"
                  defaultValue={name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  required
                  className="text-field"
                />
                <SimpleTextField
                  id="phone"
                  label="Phone"
                  defaultValue={phone}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  inputProps={{
                    maxLength: 8,
                    inputMode: "number",
                    pattern: "[0-9]*",
                  }}
                  error={error}
                  className="text-field"
                  required
                />
                <SimpleTextField
                  id="nric"
                  label="NRIC/FIN (Last 4 digits)"
                  defaultValue={nric}
                  value={nric}
                  onChange={(e) => handleChange("nric", e.target.value)}
                  inputProps={{
                    maxLength: 9,
                  }}
                  required
                  className="text-field"
                />
                <SimpleTextField
                  id="company"
                  label="Company"
                  defaultValue={company}
                  onChange={(e) => handleChange("company", e.target.value)}
                  className="text-field"
                />
                {temperatureShow && (
                  <SimpleTextField
                    id="temperature"
                    label="Temperature"
                    defaultValue={temperature}
                    onChange={(e) =>
                      handleChange("temperature", e.target.value)
                    }
                    required
                    className="text-field"
                  />
                )}
                <CustomFormControl
                  variant="outlined"
                  className={"text-field"}
                  style={{ ...styles.input, ...styles.text }}
                >
                  <Asynchronous
                    label="Block"
                    name="block"
                    handleChange={(e) => handleChange("block", e.target.value)}
                    dataPromise={blockList}
                    required
                  />
                </CustomFormControl>
                <p
                  className="custom-text"
                  style={{ margin: "", textAlign: "start" }}
                >
                  Vehicle Details :
                </p>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="car"
                    name="car"
                    onClick={(e) => {
                      handleChange("mode_of_entry", e.target.id);
                    }}
                    checked={mode_of_entry === "car" ? true : false}
                  />
                  <label className="label" for="car">
                    Car/M-Bike
                  </label>
                  <input
                    type="radio"
                    id="escooter"
                    name="selector"
                    onClick={(e) => {
                      handleChange("mode_of_entry", e.target.id);
                    }}
                    checked={mode_of_entry === "escooter" ? true : false}
                  />
                  <label className="label" for="escooter" name="escooter">
                    Bicycle
                  </label>
                  <input
                    type="radio"
                    id="walkin"
                    name="selector"
                    onChange={(e) => {
                      handleChange("mode_of_entry", e.target.id);
                    }}
                    checked={mode_of_entry === "walkin" ? true : false}
                  />
                  <label className="label" for="walkin">
                    Walk In
                  </label>
                </div>
                {(mode_of_entry === "car" || mode_of_entry === "bike") && (
                  <SimpleTextField
                    id="number_plate"
                    label="Number Plate"
                    defaultValue={number_plate}
                    onChange={(e) =>
                      handleChange("number_plate", e.target.value)
                    }
                    required
                  />
                )}
              </div>
              {errorMessage && (
                <span
                  style={{
                    margin: "15px auto 10px",
                    color: "#DB3B44",
                  }}
                >
                  Please fill all fields
                </span>
              )}
              <SubmitButton text="SUBMIT" loading={loading} />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Contractor;
