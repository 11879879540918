import makeRequest from ".";

export function fetchInviteDetails(id) {
  return makeRequest({
    uri: `/api/v1/invites/${id}/details`,
  });
}

export function updateInvite(id, requestBody) {
  return makeRequest({
    method: "PUT",
    uri: `/api/v1/invites/${id}`,
    body: JSON.stringify(requestBody),
  });
}
