import React from "react";

export const Guest = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.3466 20.7711C18.8899 14.3153 11.8102 10.9274 9.53272 13.204C9.28282 13.4658 9.09894 13.7833 8.99627 14.1303L8.95961 14.0937L6.78463 23.0069L4.6188 31.8934L2.05147 42.416L0.42627 49.0801L10.0484 45.439L22.2869 40.8083L32.0506 37.1139L32.039 37.1022C32.3658 36.998 32.6649 36.8212 32.9136 36.5849C35.1911 34.3075 31.8032 27.2277 25.3466 20.7711Z" fill="#DD1C4B" />
            <path d="M25.3464 20.7684C18.89 14.312 11.8101 10.924 9.53292 13.2012C7.25577 15.4783 10.6437 22.5583 17.1002 29.0147C23.5566 35.4711 30.6365 38.8591 32.9137 36.5819C35.1908 34.3048 31.8029 27.2248 25.3464 20.7684Z" fill="#D1E3EE" />
            <path d="M31.1643 37.4016C29.9985 37.422 28.8419 37.1925 27.7723 36.7285L19.7921 39.7482L7.54933 44.3747L0.984385 46.8587L0.42627 49.147L10.0484 45.5059L22.2869 40.8752L31.2476 37.4849L31.1643 37.4016Z" fill="#B21D48" />
            <path d="M41.2435 30.8211C41.1438 30.8207 41.0449 30.8029 40.9512 30.7686C36.7223 29.1843 32.151 28.7401 27.6963 29.4807L24.7208 29.9764C24.2654 30.0414 23.8434 29.7248 23.7785 29.2694C23.7157 28.8293 24.0094 28.4175 24.4459 28.3337L27.4223 27.8339C32.1656 27.0457 37.0328 27.5184 41.5359 29.2042C41.9689 29.3597 42.1937 29.8368 42.0381 30.2698C41.918 30.6043 41.5989 30.8256 41.2435 30.8211Z" fill="#FFBF41" />
            <path d="M26.2495 25.8231C25.7895 25.8203 25.4187 25.4452 25.4214 24.9852C25.4233 24.6676 25.6055 24.3787 25.8913 24.2403L43.3845 15.9102C43.8 15.7124 44.2971 15.8888 44.4949 16.3043C44.6927 16.7197 44.5163 17.2168 44.1009 17.4147L26.6077 25.7448C26.4957 25.7971 26.3733 25.8239 26.2495 25.8231Z" fill="#FFBF41" />
            <path d="M16.5082 24.1567C16.3984 24.1569 16.2896 24.1351 16.1884 24.0925C15.7637 23.9154 15.5631 23.4277 15.7402 23.0031V23.003L16.9514 20.0966C18.8149 15.6436 19.2495 10.7218 18.1951 6.01126C18.087 5.56404 18.362 5.11401 18.8091 5.00592C19.2563 4.89784 19.7064 5.17284 19.8144 5.61995C19.8168 5.62985 19.819 5.63974 19.8211 5.64973C20.9507 10.6958 20.4849 15.9681 18.4883 20.738L17.2763 23.6444C17.1469 23.9544 16.8441 24.1564 16.5082 24.1567Z" fill="#FFBF41" />
            <path d="M45.4087 34.1533C46.3288 34.1533 47.0747 33.4074 47.0747 32.4873C47.0747 31.5672 46.3288 30.8213 45.4087 30.8213C44.4886 30.8213 43.7427 31.5672 43.7427 32.4873C43.7427 33.4074 44.4886 34.1533 45.4087 34.1533Z" fill="#D5557E" />
            <path d="M25.4165 10.8291C26.3366 10.8291 27.0825 10.0832 27.0825 9.16309C27.0825 8.24297 26.3366 7.49707 25.4165 7.49707C24.4964 7.49707 23.7505 8.24297 23.7505 9.16309C23.7505 10.0832 24.4964 10.8291 25.4165 10.8291Z" fill="#DD95C1" />
            <path d="M17.9194 3.33204C16.9993 3.33204 16.2534 2.58618 16.2534 1.66602C16.2534 0.745855 16.9993 0 17.9194 0C18.8396 0 19.5854 0.745855 19.5854 1.66602C19.5854 2.58618 18.8396 3.33204 17.9194 3.33204Z" fill="#7FCCCB" />
            <path d="M41.2432 6.66407C40.7831 6.66407 40.4102 6.29109 40.4102 5.83106V4.99805C40.4102 4.53802 40.7831 4.16504 41.2432 4.16504C41.7032 4.16504 42.0762 4.53802 42.0762 4.99805V5.83106C42.0762 6.29109 41.7033 6.66407 41.2432 6.66407Z" fill="#2276BB" />
            <path d="M41.2432 2.49903C40.7831 2.49903 40.4102 2.12605 40.4102 1.66602V0.833009C40.4102 0.37298 40.7831 0 41.2432 0C41.7032 0 42.0762 0.37298 42.0762 0.833009V1.66602C42.0762 2.12605 41.7033 2.49903 41.2432 2.49903Z" fill="#2276BB" />
            <path d="M43.7422 4.16504H42.9092C42.4492 4.16504 42.0762 3.79206 42.0762 3.33203C42.0762 2.872 42.4492 2.49902 42.9092 2.49902H43.7422C44.2022 2.49902 44.5752 2.872 44.5752 3.33203C44.5752 3.79206 44.2023 4.16504 43.7422 4.16504Z" fill="#2276BB" />
            <path d="M39.5771 4.16504H38.7441C38.2841 4.16504 37.9111 3.79206 37.9111 3.33203C37.9111 2.872 38.2841 2.49902 38.7441 2.49902H39.5771C40.0372 2.49902 40.4101 2.872 40.4101 3.33203C40.4101 3.79206 40.0373 4.16504 39.5771 4.16504Z" fill="#2276BB" />
            <path d="M7.03903 24.1571C6.94573 23.8006 6.86243 23.4407 6.78496 23.0742L4.61914 31.9608L4.78574 33.9925C5.14883 38.324 7.00956 42.3937 10.0479 45.5022L22.2873 40.8756C22.2206 40.8548 22.1515 40.8398 22.0848 40.8173C14.6632 38.0345 9.05366 31.823 7.03903 24.1571Z" fill="white" />
            <path d="M22.2869 40.876C22.2202 40.8551 22.1511 40.8401 22.0845 40.8177C21.2827 40.5141 20.4974 40.1685 19.7321 39.7822L8.70801 43.9531C9.12597 44.4952 9.5734 45.0138 10.0483 45.5067L22.2869 40.876Z" fill="#F6F6E7" />
            <path d="M35.4125 9.97852V14.1611H31.23V9.97852H35.4125Z" fill="#C9DA53" />
            <path d="M20.4182 24.99C19.9582 24.9899 19.5853 24.6168 19.5854 24.1568C19.5856 23.9208 19.6856 23.696 19.861 23.5381L28.191 16.041C28.5389 15.74 29.065 15.778 29.366 16.1258C29.6587 16.464 29.632 16.973 29.3056 17.2788L20.9755 24.7759C20.8227 24.9137 20.6241 24.9899 20.4182 24.99Z" fill="#FFBF41" />
            <path d="M44.5757 21.6582C44.1157 21.6582 43.7427 21.2852 43.7427 20.8252V19.9922C43.7427 19.5322 44.1157 19.1592 44.5757 19.1592C45.0357 19.1592 45.4087 19.5322 45.4087 19.9922V20.8252C45.4087 21.2852 45.0358 21.6582 44.5757 21.6582Z" fill="#FFDB43" />
            <path d="M47.5211 20.4381C47.3 20.4387 47.0879 20.3508 46.9322 20.194L46.3432 19.605C46.0237 19.2741 46.0327 18.7467 46.3636 18.4272C46.6864 18.1154 47.1982 18.1154 47.5211 18.4272L48.11 19.0161C48.4353 19.3415 48.4352 19.8689 48.1098 20.1942C47.9538 20.3503 47.742 20.4381 47.5211 20.4381Z" fill="#FFDB43" />
            <path d="M48.7407 17.4932H47.9077C47.4477 17.4932 47.0747 17.1202 47.0747 16.6602C47.0747 16.2001 47.4477 15.8271 47.9077 15.8271H48.7407C49.2007 15.8271 49.5737 16.2001 49.5737 16.6602C49.5737 17.1202 49.2008 17.4932 48.7407 17.4932Z" fill="#FFDB43" />
            <path d="M46.9324 15.1374C46.4724 15.1372 46.0995 14.7643 46.0996 14.3041C46.0996 14.0833 46.1874 13.8715 46.3435 13.7154L46.9324 13.1265C47.2633 12.8068 47.7907 12.816 48.1103 13.1469C48.4221 13.4697 48.4221 13.9814 48.1103 14.3043L47.5214 14.8933C47.3652 15.0495 47.1534 15.1374 46.9324 15.1374Z" fill="#FFDB43" />
            <path d="M44.5757 14.1611C44.1157 14.1611 43.7427 13.7882 43.7427 13.3281V12.4951C43.7427 12.0351 44.1157 11.6621 44.5757 11.6621C45.0357 11.6621 45.4087 12.0351 45.4087 12.4951V13.3281C45.4087 13.7882 45.0358 14.1611 44.5757 14.1611Z" fill="#FFDB43" />
            <path d="M42.2189 15.1373C41.9979 15.1373 41.7861 15.0494 41.6299 14.8932L41.041 14.3043C40.7214 13.9734 40.7305 13.446 41.0614 13.1264C41.3842 12.8146 41.896 12.8146 42.2189 13.1264L42.8078 13.7153C43.1331 14.0407 43.133 14.5681 42.8076 14.8934C42.6515 15.0495 42.4397 15.1373 42.2189 15.1373Z" fill="#FFDB43" />
            <path d="M4.59131 0.833008L5.87831 3.44116L8.75636 3.85933L6.67384 5.88937L7.16531 8.75575L4.59131 7.40212L2.01732 8.75575L2.50879 5.88937L0.42627 3.85933L3.30432 3.44116L4.59131 0.833008Z" fill="#FFDB43" />
            <path d="M49.3104 45.1204C48.9142 43.7163 48.0019 42.5139 46.7564 41.7542L46.4132 41.2394C44.4888 38.3617 41.4628 36.405 38.0489 35.8307C37.5935 35.7657 37.1716 36.0823 37.1066 36.5377C37.0438 36.9778 37.3375 37.3896 37.774 37.4734C40.2364 37.8914 42.4804 39.1423 44.1307 41.017C42.7432 41.2666 41.5298 42.0999 40.7987 43.3053C39.9015 44.9121 40.4768 46.9418 42.0836 47.8389C43.6904 48.7361 45.7201 48.1608 46.6172 46.554C46.9599 45.8925 47.145 45.1606 47.1579 44.4157C48.0442 45.703 48.1151 47.3841 47.3403 48.7415C47.1056 49.1372 47.2361 49.6482 47.6318 49.8829C48.0274 50.1176 48.5385 49.9872 48.7732 49.5915C48.7807 49.5788 48.7879 49.566 48.7947 49.5529C49.5515 48.2035 49.7372 46.6074 49.3104 45.1204ZM45.1645 45.741C44.7199 46.5466 43.7064 46.8392 42.9009 46.3946C42.0953 45.95 41.8027 44.9365 42.2473 44.131C42.2498 44.1265 42.2523 44.122 42.2548 44.1175C42.7464 43.3126 43.5755 42.773 44.5106 42.6497C44.6554 42.6458 44.7988 42.6791 44.9271 42.7464C45.6301 43.1387 45.6676 44.8397 45.1645 45.741Z" fill="#2BB3CE" />
        </svg>
    )
}

export const Delivery = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.0703 0C19.6471 0 17.6758 1.97129 17.6758 4.39453V5.95703L20.6055 7.42188H22.0703H23.5352L26.4648 5.95703V4.39453C26.4648 1.97129 24.4936 0 22.0703 0Z" fill="#FF6A4D" />
            <path d="M26.4648 5.95703V4.39453C26.4648 1.97129 24.4936 0 22.0703 0V7.42188H23.5352L26.4648 5.95703Z" fill="#E64D2E" />
            <path d="M0 44.1406C0.678809 47.4836 3.78086 50 7.32422 50C10.8676 50 14.0673 47.4836 14.7461 44.1406L11.5637 42.6758H2.03359L0 44.1406Z" fill="#474F54" />
            <path d="M7.32422 47.0703C4.90098 47.0703 2.92969 45.099 2.92969 42.6758C2.92969 40.2525 4.90098 38.2812 7.32422 38.2812C9.74746 38.2812 11.8164 40.2525 11.8164 42.6758C11.8164 45.099 9.74746 47.0703 7.32422 47.0703Z" fill="#F2F9FF" />
            <path d="M14.7461 17.7734H5.85938L2.92969 20.7031V29.4922L5.85938 30.957H14.7461L19.1406 29.4922V21.582L17.6758 20.7031L14.7461 17.7734Z" fill="#FF9100" />
            <path d="M2.92969 14.8438V20.7031H17.6758L19.1406 19.9448L17.6758 19.2383V14.8438H2.92969Z" fill="#FABE2C" />
            <path d="M23.5352 44.1406H0V42.6758C0 37.0127 4.68848 32.4219 10.3516 32.4219L12.793 30.957H26.4648V41.6992L23.5352 44.1406Z" fill="#FF6A4D" />
            <path d="M0 29.4922H20.6055V32.4219H0V29.4922Z" fill="#32393F" />
            <path d="M30.8594 36.9042V42.412L29.3945 44.1406H23.5352V32.4219H20.6055C18.9941 32.4219 17.6758 31.1035 17.6758 29.4922V22.168H26.4648V26.5625C28.0762 26.5625 29.3945 27.8809 29.3945 29.4922V35.3516L30.8594 36.9042Z" fill="#575F64" />
            <path d="M30.8594 36.9042V42.412L29.3945 44.1406H23.5352V32.4219H22.0703V22.168H26.4648V26.5625C28.0762 26.5625 29.3945 27.8809 29.3945 29.4922V35.3516L30.8594 36.9042Z" fill="#474F54" />
            <path d="M26.4648 20.7031V19.2383C26.4648 16.815 24.4936 14.8438 22.0703 14.8438C19.6471 14.8438 17.6758 16.815 17.6758 19.2383V22.168C17.6758 24.595 19.6433 26.5625 22.0703 26.5625H33.8867C36.3138 26.5625 38.2812 24.595 38.2812 22.168V20.7031H26.4648Z" fill="#FF6A4D" />
            <path d="M38.2812 22.168V20.7031H26.4648V19.2383C26.4648 16.815 24.4936 14.8438 22.0703 14.8438V26.5625H33.8867C36.3138 26.5625 38.2812 24.595 38.2812 22.168Z" fill="#E64D2E" />
            <path d="M23.5352 7.42188H22.0703H20.6055L17.6758 8.88672V10.3516C17.6758 12.7748 19.6471 14.8438 22.0703 14.8438C24.4936 14.8438 26.4648 12.7748 26.4648 10.3516V8.88672L23.5352 7.42188Z" fill="#FFE1BA" />
            <path d="M26.4648 10.3516V8.88672L23.5352 7.42188H22.0703V14.8438C24.4936 14.8438 26.4648 12.7748 26.4648 10.3516Z" fill="#FFBFAB" />
            <path d="M22.0703 5.95703H17.6758V8.88672H22.0703H29.3945V5.95703H22.0703Z" fill="#474F54" />
            <path d="M22.0703 5.95703H29.3945V8.88672H22.0703V5.95703Z" fill="#32393F" />
            <path d="M42.3182 29.8197C42.1565 28.6796 41.836 26.4337 41.1967 21.9604L35.3516 19.2383V30.957C35.3516 33.2679 33.3608 35.3516 30.8594 35.3516H29.3945V44.1406H32.3242H47.0703L50 42.6758V39.7461C50 35.0598 46.7671 30.9742 42.3182 29.8197V29.8197Z" fill="#E64D2E" />
            <path d="M44.1406 23.6328H39.7461C37.3229 23.6328 35.3516 21.6615 35.3516 19.2383C35.3516 16.815 37.3229 14.8438 39.7461 14.8438H44.1406V23.6328Z" fill="#FF6A4D" />
            <path d="M43.116 35.3961L42.69 37.0238L40.2144 35.8077C37.3892 36.8244 35.3516 39.5049 35.3516 42.6758C35.3516 46.7142 38.6374 50 42.6758 50C46.7142 50 50 46.7142 50 42.6758C50 38.7882 46.946 35.6289 43.116 35.3961V35.3961Z" fill="#32393F" />
            <path d="M42.6758 38.2812C40.2525 38.2812 38.2812 40.2525 38.2812 42.6758C38.2812 45.099 40.2525 47.0703 42.6758 47.0703C45.099 47.0703 47.0703 45.099 47.0703 42.6758C47.0703 40.2525 45.099 38.2812 42.6758 38.2812Z" fill="#DFE7F4" />
            <path d="M44.1263 42.4684L43.116 35.3961C42.9673 35.3871 42.8265 35.3516 42.6757 35.3516C41.8083 35.3516 40.9871 35.5297 40.2144 35.8077L41.2252 42.8832L44.1263 42.4684Z" fill="#C7CFE1" />
        </svg>
    )
}

export const Contractor = () => {
    return (
        <svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.0345 33.2586V50H0V33.2586C0.0015373 32.1243 0.375078 31.0218 1.06334 30.1203C1.75161 29.2187 2.7166 28.5677 3.81034 28.2672L8.62069 26.862V31.0344C8.62069 30.5772 8.80234 30.1386 9.12567 29.8153C9.44901 29.492 9.88755 29.3103 10.3448 29.3103C10.8021 29.3103 11.2406 29.492 11.564 29.8153C11.8873 30.1386 12.069 30.5772 12.069 31.0344V28.4482L15.5172 31.0344L18.9655 28.4482V31.0344C18.9655 30.5772 19.1472 30.1386 19.4705 29.8153C19.7938 29.492 20.2324 29.3103 20.6896 29.3103C21.1469 29.3103 21.5855 29.492 21.9088 29.8153C22.2321 30.1386 22.4138 30.5772 22.4138 31.0344V26.862L27.2241 28.2672C28.2351 28.5453 29.138 29.1235 29.8137 29.9253C30.4894 30.7272 30.906 31.7151 31.0086 32.7586C31.026 32.9247 31.0347 33.0916 31.0345 33.2586Z" fill="#3D9AE2" />
            <path d="M31.0086 32.7586H28.4482V36.2069H31.0344H37.0689V32.7586H31.0086Z" fill="#FF5023" />
            <path d="M27.586 39.6552V50H17.2412V39.6552L18.9653 36.2069H25.8619L27.586 39.6552Z" fill="#ED1C24" />
            <path d="M44.8273 39.6552V42.2414H27.586V39.6552L25.8618 36.2069H43.1032L44.8273 39.6552Z" fill="#E01A22" />
            <path d="M27.5864 42.2413H44.8278V50H27.5864V42.2413Z" fill="#FF5023" />
            <path d="M37.9312 42.2414V43.1035C37.9312 43.5607 37.7495 43.9993 37.4262 44.3226C37.1029 44.6459 36.6643 44.8276 36.207 44.8276C35.7498 44.8276 35.3112 44.6459 34.9879 44.3226C34.6646 43.9993 34.4829 43.5607 34.4829 43.1035V41.3793C34.4843 40.9225 34.6664 40.4847 34.9894 40.1617C35.3124 39.8386 35.7502 39.6565 36.207 39.6552C36.6633 39.6586 37.0998 39.8413 37.4225 40.1639C37.7451 40.4865 37.9278 40.9231 37.9312 41.3793V42.2414Z" fill="#D1D3D4" />
            <path d="M22.4136 26.8621V31.0345C22.4136 30.5772 22.232 30.1387 21.9086 29.8153C21.5853 29.492 21.1467 29.3103 20.6895 29.3103C20.2322 29.3103 19.7937 29.492 19.4703 29.8153C19.147 30.1387 18.9653 30.5772 18.9653 31.0345V25.8621L22.4136 26.8621Z" fill="#1E81CE" />
            <path d="M12.0689 28.4483V31.0345C12.0689 30.5772 11.8872 30.1387 11.5639 29.8153C11.2406 29.492 10.802 29.3103 10.3447 29.3103C9.88747 29.3103 9.44893 29.492 9.12559 29.8153C8.80226 30.1387 8.62061 30.5772 8.62061 31.0345V26.8621L12.0689 25.8621V28.4483Z" fill="#1E81CE" />
            <path d="M25.0002 11.2069C25.0006 11.5838 24.8767 11.9503 24.6475 12.2495C24.4184 12.5488 24.0969 12.7641 23.733 12.862C23.5857 12.9108 23.4312 12.9341 23.2761 12.931H7.75883C7.60372 12.9341 7.44922 12.9108 7.30193 12.862C7.01177 12.7922 6.7482 12.6394 6.54331 12.4224C6.38124 12.2643 6.25268 12.0752 6.16529 11.8664C6.0779 11.6576 6.03349 11.4332 6.03469 11.2069C6.03606 10.75 6.21814 10.3123 6.54119 9.98922C6.86423 9.66618 7.30198 9.48409 7.75883 9.48273H23.2761C23.7323 9.48611 24.1689 9.66885 24.4915 9.99145C24.8141 10.3141 24.9968 10.7506 25.0002 11.2069V11.2069Z" fill="#F7910C" />
            <path d="M16.3792 0H14.655C14.4264 0 14.2071 0.0908248 14.0455 0.252494C13.8838 0.414163 13.793 0.633434 13.793 0.862069V9.48275H17.2412V0.862069C17.2412 0.633434 17.1504 0.414163 16.9888 0.252494C16.8271 0.0908248 16.6078 0 16.3792 0V0Z" fill="#FFA733" />
            <path d="M18.9654 22.4138V28.4483L15.5171 31.0345L12.0688 28.4483V22.4138H18.9654Z" fill="#FFC477" />
            <path d="M18.9654 22.4138V25.0517C17.894 25.5847 16.7137 25.862 15.5171 25.862C14.3205 25.862 13.1402 25.5847 12.0688 25.0517V22.4138H18.9654Z" fill="#FFB655" />
            <path d="M23.276 12.931V16.3793C23.2757 17.6996 22.9384 18.998 22.2962 20.1516C21.6539 21.3051 20.7279 22.2757 19.6057 22.9714C18.4836 23.6672 17.2025 24.065 15.8836 24.1273C14.5648 24.1897 13.2519 23.9144 12.0691 23.3276C10.7768 22.6833 9.68919 21.6922 8.92797 20.4651C8.16675 19.238 7.76193 17.8233 7.75879 16.3793V12.931H23.276Z" fill="#FFC477" />
            <path d="M7.75879 12.931H23.276V14.6552H7.75879V12.931Z" fill="#FFB655" />
            <path d="M22.1033 5.39658C21.5998 4.56588 20.9378 3.84237 20.155 3.26727C19.2892 2.61705 18.2965 2.15595 17.2412 1.91382V9.48278H23.2757C23.2805 8.03706 22.8739 6.6198 22.1033 5.39658V5.39658Z" fill="#FF9811" />
            <path d="M8.93125 5.39658C8.16063 6.6198 7.75399 8.03706 7.75883 9.48278H13.7933V1.91382C12.738 2.15595 11.7453 2.61705 10.8795 3.26727C10.0967 3.84237 9.43471 4.56588 8.93125 5.39658V5.39658Z" fill="#FF9811" />
            <path d="M15.5171 21.5518C14.6026 21.5518 13.7255 21.1885 13.0788 20.5418C12.4321 19.8951 12.0688 19.018 12.0688 18.1035H13.793C13.793 18.5608 13.9746 18.9993 14.298 19.3226C14.6213 19.646 15.0599 19.8276 15.5171 19.8276C15.9744 19.8276 16.4129 19.646 16.7363 19.3226C17.0596 18.9993 17.2413 18.5608 17.2413 18.1035H18.9654C18.9654 19.018 18.6021 19.8951 17.9554 20.5418C17.3087 21.1885 16.4317 21.5518 15.5171 21.5518Z" fill="#FF9811" />
            <path d="M11.207 14.6552H12.9312V16.3793H11.207V14.6552Z" fill="#FF9811" />
            <path d="M18.103 14.6552H19.8272V16.3793H18.103V14.6552Z" fill="#FF9811" />
            <path d="M5.17236 36.2069H6.8965V50H5.17236V36.2069Z" fill="#1E81CE" />
            <path d="M37.9312 36.2069H36.2071V33.6207H29.3106V36.2069H27.5864V32.7586C27.5864 32.53 27.6772 32.3107 27.8389 32.149C28.0006 31.9874 28.2199 31.8965 28.4485 31.8965H37.0692C37.2978 31.8965 37.5171 31.9874 37.6788 32.149C37.8404 32.3107 37.9312 32.53 37.9312 32.7586V36.2069Z" fill="#ED1C24" />
        </svg>

    )
}