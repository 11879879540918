import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControl, TextField } from "@material-ui/core";
import { color } from "../config.js";
import { DateTimePicker } from "@material-ui/pickers";

export const styles = {
  text: {
    fontStyle: "normal",
    fontSize: 18,
    lineHeight: 22,
  },
  input: {
    width: 300,
    marginBottom: 15,
    marginRight: 10,
  },
};

const wrapper = (Comp) =>
  withStyles({
    root: {
      "& label.Mui-focused": {
        color: "#dedede",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#dedede",
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: "#dedede",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#dedede",
        },
      },
    },
  })(Comp);

export const CustomTextField = wrapper(TextField);
export const CustomFormControl = wrapper(FormControl);
export const CustomDateTimePicker = wrapper(DateTimePicker);

export const SimpleTextField = (props) => (
  <CustomTextField
    variant="outlined"
    style={{ ...styles.input }}
    InputLabelProps={{
      style: {
        color,
      },
    }}
    className="text-field"
    {...props}
  />
);

export const SimplePhoneField = (props) => {
  return <SimpleTextField
    inputProps={{
      maxLength: 10,
      inputMode: "number",
      pattern: "[0-9]*",
    }}
    {...props}
  />
}
