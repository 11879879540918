import React from "react";
import Invite from "../views/Invite/Invite.js";
import SuccessPage from "./SuccessPage";
import {color} from "../config";
import moment from "moment";
import {fetchInviteDetails, updateInvite} from "../api/invite";

class Invites extends React.Component {
  constructor(props) {
    super(props);
    this.handleRef = React.createRef();
    this.invite_id = "";
    this.contractor = {
      name: "",
      phone: "",
      nric: "",
      vehicle_number: "",
    };
    this.state = {
      invitees: [{...this.contractor}],
      visiting_time: new Date(),
      condoName: "",
      submitted: false,
      snackbar: {
        visible: false,
        message: "",
        variant: "",
      },
      not_found: false,
      nricPrier: "XXXXX",
    };
  }

  componentDidMount = () => {
    this.invite_id = this.props.match.params.invite_id;
    fetchInviteDetails(this.invite_id)
      .then(({data}) => {
        this.setState(
          {
            status: data.invite.status,
            condoName: data.condo.name,
            invitees: [
              {
                name: data.name,
                nric: data.nric,
                vehicle_number: data.vehicle_number,
                phone: data.phone,
                status: data.status,
              },
            ],
          },
          () => {
            if (
              this.state.invitees[0].name ||
              this.state.invitees[0].phone ||
              this.state.invitees[0].nric
            ) {
              let temp = this.state.invitees;
              temp.push({...this.contractor});
              this.setState({invitees: temp});
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({not_found: true});
      });
  };

  handleChange = (event, index) => {
    let {invitees: temp, nricPrier} = this.state;
    let ln = temp.length;
    if (index === ln - 1) {
      if (
        temp[index]["phone"] ||
        temp[index]["name"] ||
        temp[index]["nric"] ||
        temp[index]["vehicle_number"]
      ) {
      } else {
        temp.push({...this.contractor});
      }
    }
    if (event.target.id === "nric") {
      temp[index][event.target.id] = temp[index][event.target.id]
        ? event.target.value.slice(0, 9)
        : `${nricPrier}${event.target.value.slice(0, 4)}`;
      if (temp[index][event.target.id].length < 6) {
        temp[index][event.target.id] = "";
      }
    } else {
      temp[index][event.target.id] = event.target.value;
    }

    if (
      temp[index]["phone"] ||
      temp[index]["name"] ||
      temp[index]["nric"] ||
      temp[index]["vehicle_number"]
    ) {
      this.setState({
        invitees: temp,
      });
    } else {
      temp.length > 1 && temp.pop();
      this.setState({
        invitees: temp,
      });
    }
    if (ln === 1) {
      if (temp[0]["phone"] || temp[0]["name"]) {
        temp.push({...this.contractor});
      }
    }
  };

  handleDelete = (index) => {
    let temp = this.state.invitees;
    temp.splice(index, 1);
    this.setState({invitees: temp});
  };

  dateChange = (id, value) => {
    this.setState({[id]: value});
  };

  onClose = () => {
    this.setState({
      snackbar: {
        visible: false,
        message: "",
        variant: "",
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {invitees, visiting_time} = this.state;
    let invites = {
      invites: {
        invitees,
        visiting_time:
          moment(visiting_time).utc().format("YYYY-MM-DD HH:mm:ss") + " +0000",
      },
    };
    updateInvite(this.invite_id, invites)
      .then(({message}) => {
        this.setState({
          snackbar: {
            visible: true,
            message: message,
            variant: "success",
          },
          submitted: true,
        });
      })
      .catch((err) => {
        console.log(err);
        let msg = "Something is not right..!";
        this.setState({
          snackbar: {
            visible: true,
            message: msg,
            variant: "error",
            value: true,
          },
        });
      });
  };

  StatusInvite = () => {
    switch (this.state.status) {
      case "created":
        return (
          <Invite
            data={this.state}
            handleChange={this.handleChange}
            handleDelete={this.handleDelete}
            dateChange={this.dateChange}
            handleSubmit={this.handleSubmit}
            onClose={this.onClose}
            condos={this.props.about}
          />
        );
      default:
        return (
          <SuccessPage
            image="cancelled.png"
            content={["Ohoo...!!! This Invite is already updated."]}
            data={
              <p className="content-title" style={{color: color}}>
                Ask the sender to generate to new invite. or Click{" "}
                <a href={`/invitations/${this.invite_id}`}>here</a> to get the
                QR Pass.
              </p>
            }
            textColor={color}
          />
        );
    }
  };

  render() {
    const {status, not_found} = this.state;

    if (not_found) {
      return (
        <SuccessPage
          image="warning.png"
          content={["Ohoo...!!! This Invite is invalid"]}
          textColor={color}
        />
      );
    }
    return (
      <div style={{minHeight: "85.6vh"}}>{status && this.StatusInvite()}</div>
    );
  }
}

export default Invites;
