import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CustomDateTimePicker } from "./FormField";
import DateFnsUtils from "@date-io/date-fns";
import { color } from "../config";

const styles = {
  text: {
    fontFamily: '"Montserrat"',
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "22px",
  },
  input: {
    width: 300,
    marginBottom: 15,
  },
};

const DateTimeField = ({
  onChange,
  allowedHours,
  errorMessage,
  language,
  disableDay,
  className,
  ...rest
}) => {
  const validateDate = (date) => {
    const selectedDate = new Date(date);

    if (selectedDate < new Date()) {
      onChange(date, "* Time can't be in past", false);
      return;
    }

    const valid = selectedDate.getHours();
    const helperText = !valid && "Select Valid time";
    onChange(helperText, valid, date);
  };

  const enableFuture = () => {
    let date = new Date(); // Now
    date.setDate(date.getDate() + 45);
    return date;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CustomDateTimePicker
        required
        disablePast
        inputVariant="outlined"
        maxDate={enableFuture()}
        onChange={(date) => validateDate(date)}
        style={{ ...styles.input }}
        format={"dd-MMM-yyyy hh:mm a"}
        InputLabelProps={{
          style: { color },
        }}
        variant="outlined"
        className={`datetime-picker text-field`}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateTimeField