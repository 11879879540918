import React from "react";
import { success } from "../../config";
class SuccessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="main-root" style={{ backgroundColor: "#ffffff" }}>
          <div
            className="main-container"
            style={{ backgroundColor: "#ffffff" }}
          >
            <img
              src={success}
              className="head-icon"
              style={{ marginBottom: 0 }}
              alt="bms"
            />

            <p className="content-title">
              Thank you.....!
              <br /> Subscribed Notification
            </p>
            <p>
              <a href={"/residents/subscribe"}>Go to home page </a>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SuccessPage;
