import React from "react";
import { color } from "../config";

const Head = (props) => {
  return (
    <header
      className="header"
      style={{ backgroundColor: color, color: "#ffff" }}
    >
      <div style={{ textAlign: "center" }}>
        <h1>{props.condo}</h1>
        <h3 style={{ marginBottom: "0px" }}>{props.subHeading}</h3>
      </div>
    </header>
  );
};
export default Head;
