import React from 'react';
import { NotFound } from './IndexIcon';
import { color } from '../config';

export const PageNotFound = () => {
  return (
    <React.Fragment>
      <div className="main-root" style={{ paddingTop: '5%' }}>
        <div className="main-container">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50px',
            }}
          >
            <NotFound style={{ marginTop: '20px' }} />
          </div>
          <h1 style={{ textAlign: 'center', marginTop: '50px' }}>Error: 404</h1>
          <h1 style={{ textAlign: 'center', color: color, marginTop: '50px' }}>
            Page Not Found
          </h1>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PageNotFound;
