import React from "react";

const InviteSuccess = () => {
  return (
    <svg
      className="invite-success"
      viewBox="0 0 243 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M173.601 45.7314H171.874C171.533 45.7314 171.257 46.008 171.257 46.3491V69.5506C171.257 69.8917 171.533 70.1682 171.874 70.1682H173.601C173.941 70.1682 174.217 69.8917 174.217 69.5506V46.3491C174.217 46.008 173.941 45.7314 173.601 45.7314Z"
          fill="#3F3D56"
        />
        <path
          d="M162.357 0H84.3338C78.5363 0 73.8364 4.70816 73.8364 10.516V191.079C73.8364 196.887 78.5363 201.595 84.3338 201.595H162.357C168.154 201.595 172.854 196.887 172.854 191.079V10.516C172.854 4.70816 168.154 0 162.357 0Z"
          fill="#3F3D56"
        />
        <path
          d="M159.072 5.25098H147.714V6.61975C147.714 7.47176 147.546 8.31542 147.221 9.10253C146.895 9.88964 146.418 10.6048 145.816 11.2071C145.215 11.8094 144.501 12.287 143.715 12.6128C142.929 12.9385 142.087 13.1059 141.236 13.1054H105.114C104.263 13.1059 103.421 12.9385 102.635 12.6128C101.849 12.287 101.135 11.8094 100.533 11.2071C99.9318 10.6048 99.4546 9.88964 99.129 9.10253C98.8035 8.31542 98.6359 7.47176 98.6359 6.61975V5.25098H88.0012C85.9156 5.25098 83.9153 6.08097 82.4405 7.55837C80.9657 9.03577 80.1372 11.0396 80.1372 13.1289V188.48C80.1372 190.57 80.9657 192.573 82.4405 194.051C83.9153 195.528 85.9156 196.358 88.0012 196.358H159.072C161.158 196.358 163.158 195.528 164.633 194.051C166.108 192.573 166.936 190.57 166.936 188.48V13.1424C166.938 12.1067 166.736 11.0809 166.342 10.1235C165.947 9.16615 165.368 8.2961 164.638 7.56313C163.907 6.83017 163.04 6.24868 162.085 5.85194C161.13 5.45519 160.106 5.25098 159.072 5.25098Z"
          fill="white"
        />
        <path
          d="M126.579 6.02051H114.836C114.261 6.02051 113.794 6.48782 113.794 7.06428V7.78791C113.794 8.36437 114.261 8.83169 114.836 8.83169H126.579C127.155 8.83169 127.621 8.36437 127.621 7.78791V7.06428C127.621 6.48782 127.155 6.02051 126.579 6.02051Z"
          fill="#E6E8EC"
        />
        <path
          d="M131.306 9.02241C132.186 9.02241 132.9 8.30767 132.9 7.426C132.9 6.54433 132.186 5.82959 131.306 5.82959C130.426 5.82959 129.712 6.54433 129.712 7.426C129.712 8.30767 130.426 9.02241 131.306 9.02241Z"
          fill="#E6E8EC"
        />
        <path
          d="M123.95 122.037C134.295 122.037 142.681 113.636 142.681 103.273C142.681 92.9094 134.295 84.5083 123.95 84.5083C113.605 84.5083 105.219 92.9094 105.219 103.273C105.219 113.636 113.605 122.037 123.95 122.037Z"
          fill="white"
        />
        <path
          d="M148.587 100.653C148.587 105.613 147.118 110.462 144.367 114.586C141.616 118.71 137.706 121.924 133.131 123.822C128.557 125.72 123.523 126.217 118.667 125.249C113.811 124.281 109.35 121.892 105.849 118.385C102.348 114.877 99.9633 110.409 98.9975 105.544C98.0317 100.679 98.5276 95.6361 100.423 91.0535C102.318 86.4709 105.527 82.5541 109.644 79.7985C113.761 77.0428 118.601 75.5721 123.552 75.5723C126.84 75.5724 130.096 76.2212 133.133 77.4816C136.17 78.7421 138.93 80.5895 141.255 82.9184C143.579 85.2473 145.423 88.0121 146.681 91.055C147.939 94.0978 148.587 97.359 148.587 100.653ZM120.659 113.928L139.229 95.3239C139.38 95.1737 139.499 94.9953 139.58 94.7989C139.662 94.6025 139.703 94.392 139.703 94.1793C139.703 93.9667 139.662 93.7562 139.58 93.5598C139.499 93.3634 139.38 93.185 139.229 93.0348L136.944 90.7625C136.795 90.6121 136.617 90.4927 136.421 90.4112C136.225 90.3298 136.015 90.2878 135.803 90.2878C135.591 90.2878 135.382 90.3298 135.186 90.4112C134.99 90.4927 134.812 90.6121 134.663 90.7625L119.516 105.936L112.444 98.8347C112.14 98.5322 111.73 98.3625 111.301 98.3625C110.873 98.3625 110.463 98.5322 110.159 98.8347L107.874 101.137C107.572 101.441 107.403 101.853 107.403 102.282C107.403 102.711 107.572 103.122 107.874 103.426L118.374 113.941C118.524 114.092 118.702 114.211 118.898 114.293C119.094 114.374 119.304 114.416 119.517 114.416C119.729 114.416 119.939 114.374 120.135 114.293C120.331 114.211 120.509 114.092 120.659 113.941L120.659 113.928Z"
          fill="#57B894"
        />
        <path
          d="M76.9364 39.7437H76.6771C76.3366 39.7437 76.0605 40.0202 76.0605 40.3613V46.3577C76.0605 46.6988 76.3366 46.9754 76.6771 46.9754H76.9364C77.2769 46.9754 77.5529 46.6988 77.5529 46.3577V40.3613C77.5529 40.0202 77.2769 39.7437 76.9364 39.7437Z"
          fill="#3F3D56"
        />
        <path
          d="M77.0719 52.9854H76.6234C76.2829 52.9854 76.0068 53.2619 76.0068 53.603V64.954C76.0068 65.2951 76.2829 65.5716 76.6234 65.5716H77.0719C77.4124 65.5716 77.6885 65.2951 77.6885 64.954V53.603C77.6885 53.2619 77.4124 52.9854 77.0719 52.9854Z"
          fill="#3F3D56"
        />
        <path
          d="M77.0177 69.9756H76.6502C76.3097 69.9756 76.0337 70.2521 76.0337 70.5932V82.0524C76.0337 82.3935 76.3097 82.67 76.6502 82.67H77.0177C77.3582 82.67 77.6343 82.3935 77.6343 82.0524V70.5932C77.6343 70.2521 77.3582 69.9756 77.0177 69.9756Z"
          fill="#3F3D56"
        />
        <path
          d="M56.1997 103.544C56.2425 103.585 56.2975 103.612 56.3564 103.619C56.4154 103.627 56.4753 103.615 56.5271 103.586C56.4165 103.588 56.3063 103.574 56.1997 103.544Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M68.5746 108.393L68.2515 110.156L68.0022 111.504L67.5488 113.971L63.1525 117.055C63.1525 117.055 62.7562 116.715 62.1025 116.179C60.3008 114.693 56.5141 111.667 53.7334 110.042C53.0182 109.586 52.2443 109.228 51.4331 108.98L68.5746 108.393Z"
          fill="#FEB9B9"
        />
        <path
          d="M65.3493 109.704C65.3493 109.704 70.4752 110.586 70.7754 108.393C71.0756 106.2 68.137 105.015 65.6466 104.867C63.1562 104.719 48.2089 105.748 48.2089 105.748C48.2089 105.748 45.5724 109.271 55.0946 109.861L65.3493 109.704Z"
          fill="#3F3D56"
        />
        <path
          d="M76.5592 37.3394H49.7437V69.1931H76.5592V37.3394Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.1"
          d="M76.5592 37.4844H49.7437V69.3381H76.5592V37.4844Z"
          fill="black"
        />
        <path
          d="M18.3158 91.8691C17.8743 91.9805 17.31 92.4086 16.6824 93.0273C13.9798 95.7077 10.1091 101.998 10.1091 101.998C5.16033 96.0925 10.6365 91.4268 12.9708 89.8234C13.5383 89.4353 13.9195 89.2217 13.9195 89.2217L18.3158 91.8691Z"
          fill="#FEB9B9"
        />
        <path
          d="M68.2804 47.3931C68.2804 47.3931 69.5996 56.6403 74.1418 58.8422C78.684 61.0441 68.2804 65.1594 68.2804 65.1594L54.0679 59.8709C54.0679 59.8709 62.1276 57.2277 60.5149 49.4474L68.2804 47.3931Z"
          fill="#FEB9B9"
        />
        <path
          d="M53.0407 103.026L52.7193 104.783L52.4581 106.137L52.0047 108.604L47.6085 111.684C47.6085 111.684 47.2121 111.347 46.5584 110.812C44.7567 109.326 40.9703 106.3 38.1853 104.675C37.4704 104.218 36.6965 103.861 35.885 103.613L53.0407 103.026Z"
          fill="#FEB9B9"
        />
        <path
          d="M49.2379 105.193C49.2379 105.193 53.8383 105.225 54.115 103.026C54.3918 100.827 51.6079 99.648 49.2379 99.5005C46.8678 99.3529 32.4677 100.45 32.4677 100.45C32.4677 100.45 30.4247 104.604 39.4825 105.192L49.2379 105.193Z"
          fill="#2F2E41"
        />
        <path
          d="M81.4925 139.741C81.2914 139.642 81.09 139.54 80.8919 139.44L80.3513 138.757L73.2616 129.751V129.908C73.2136 131.412 72.7032 143.33 68.7212 144.431C64.4722 145.604 59.3463 145.899 57.0096 137.973C54.673 130.046 50.4035 112.725 46.019 111.696L52.5412 106.339C52.5412 106.339 56.8594 111.398 58.6188 116.242C58.5529 115.941 55.5709 101.983 58.0182 99.5132H79.4154C79.4154 99.5132 82.7186 108.938 84.9826 113.607C86.7453 117.231 89.3907 125.802 91.0942 131.602C92.7804 137.329 86.8556 142.385 81.4925 139.741Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M80.8832 102.145C77.58 104.103 63.0792 103.306 58.3617 102.987L57.0764 102.894C56.9383 102.894 57.0974 102.816 57.0974 102.816L57.2296 101.483L60.746 92.5306L79.574 81.5928C79.1925 82.745 79.0665 84.5078 79.0966 86.5263C79.1836 93.012 80.8832 102.145 80.8832 102.145Z"
          fill="black"
        />
        <path
          d="M80.8833 101.703C77.5801 103.661 63.0793 102.864 58.3618 102.545L57.0765 102.455L56.8633 102.437L57.0975 102.377L58.2566 102.076L60.7459 92.095L79.5738 81.1602C79.1926 82.3124 79.0663 84.0752 79.0965 86.0937C79.1837 92.5732 80.8833 101.703 80.8833 101.703Z"
          fill="#EFEFEF"
        />
        <path
          opacity="0.1"
          d="M79.0847 86.0875C75.2559 86.9719 66.83 89.5667 63.5928 95.834C61.4338 100.006 59.617 101.796 58.3498 102.545L57.0645 102.455C57.0729 102.429 57.0799 102.403 57.0855 102.377L58.2445 102.076L60.7338 92.0947L79.5618 81.1602C79.1848 82.3062 79.0545 84.069 79.0847 86.0875Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M13.9195 89.228L18.3158 91.8693C17.8742 91.9807 17.3099 92.4087 16.6823 93.0274C16.3159 91.9564 14.0169 90.4614 12.9707 89.8238C13.538 89.4416 13.9195 89.228 13.9195 89.228Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M56.2807 102.627C56.2325 102.652 56.1777 102.661 56.1238 102.654C56.07 102.646 56.0199 102.622 55.9805 102.584C56.078 102.613 56.1791 102.627 56.2807 102.627Z"
          fill="black"
        />
        <path
          d="M66.2295 58.2557C66.2295 58.2557 62.2718 58.6948 59.4882 55.759C59.4882 55.759 47.7766 59.1371 43.8103 66.7688C39.8439 74.4005 12.8806 89.3753 12.8806 89.3753C12.8806 89.3753 17.5712 92.0166 17.1296 93.337L34.8615 83.0611C34.8615 83.0611 41.1675 78.5127 42.9183 77.4841C44.6691 76.4554 54.3502 70.1442 56.4012 87.3179C58.4522 104.492 56.4012 102.437 56.4012 102.437C56.4012 102.437 59.332 103.466 63.5811 95.2444C67.8301 87.0229 81.0157 85.1158 81.0157 85.1158L81.7483 76.4581C81.7483 76.4581 84.3639 70.7184 82.349 68.3361C81.7385 67.6307 81.3325 66.7714 81.1748 65.8513C80.8415 63.6523 81.1748 59.9883 85.8412 56.9441C93.021 52.2452 95.8046 48.431 95.8046 48.431C95.8046 48.431 95.9516 45.6395 91.5556 44.3188C87.1596 42.998 69.2834 35.8057 69.2834 35.8057L68.6827 38.3024L82.7873 48.2746C82.7873 48.2746 75.7547 53.99 70.1754 53.8516C70.1751 53.8516 71.2111 58.1082 66.2295 58.2557Z"
          fill="#57B894"
        />
        <path
          opacity="0.1"
          d="M68.9437 51.4178C68.5972 50.2412 68.3264 49.0434 68.133 47.832L60.3676 49.8872C60.7099 51.3723 60.6488 52.9221 60.1904 54.3754C61.7781 54.7998 63.4554 54.7456 65.0125 54.2195C66.5695 53.6934 67.937 52.7188 68.9437 51.4178Z"
          fill="black"
        />
        <path
          d="M62.4941 54.219C67.1063 54.219 70.8451 50.4735 70.8451 45.8531C70.8451 41.2328 67.1063 37.4873 62.4941 37.4873C57.882 37.4873 54.1431 41.2328 54.1431 45.8531C54.1431 50.4735 57.882 54.219 62.4941 54.219Z"
          fill="#FEB9B9"
        />
        <path
          opacity="0.1"
          d="M71.5776 113.227C71.5776 113.227 76.4122 122.769 74.9468 125.702L71.5776 113.227Z"
          fill="black"
        />
        <path
          d="M66.9351 26.5314C69.2532 26.7962 71.4544 27.7917 73.7367 28.3362C74.3071 28.5139 74.9143 28.5367 75.4964 28.4023C76.3822 28.1286 77.1029 27.1992 78.0218 27.2261C78.5872 27.2442 79.0877 27.6293 79.5532 27.9991C80.2996 28.5714 81.0022 29.1989 81.6551 29.8763C83.2285 31.5879 84.2044 33.8923 85.6397 35.7543C87.075 37.6164 89.122 39.8 91.1609 38.9461C89.7738 44.3608 85.4475 47.748 80.5321 48.0852C78.5713 48.22 76.5623 47.8114 74.6645 48.3859C74.1918 48.58 73.6926 48.7017 73.1837 48.7468C72.0906 48.7468 71.2108 47.7421 70.5291 46.7644C68.9314 44.4662 67.6674 41.8581 65.8687 39.7614C65.268 39.0666 64.5745 38.4077 63.7336 38.2815C62.8928 38.1553 62.1212 38.5582 61.3736 38.9463C60.1394 39.5872 58.8783 40.2488 57.9204 41.353C56.9625 42.4571 56.3588 44.0873 56.7551 45.5645C56.9684 46.3647 57.4551 47.0867 57.4638 47.9228C57.4818 49.2584 56.3047 50.2331 56.0734 51.5326C56.0212 52.0784 56.0022 52.627 56.0164 53.1751C55.9143 54.9552 54.751 56.4116 53.5394 57.5339C53.065 57.967 52.5433 58.4363 52.4044 59.1071C52.3112 59.5672 52.4044 60.0511 52.3443 60.5179C52.1672 61.6731 51.017 62.2597 49.999 62.4102C48.8097 62.5866 47.5948 62.4423 46.4796 61.9922C47.9031 61.3179 48.7799 59.3992 48.4496 57.6814C47.9962 55.2988 45.606 53.5872 45.8111 51.1628C45.9235 50.4189 46.1462 49.696 46.4718 49.0181C46.9927 47.7079 47.3713 46.3454 47.601 44.9541C47.7811 43.8801 47.8683 42.7401 47.5318 41.7144C47.2316 40.7817 46.604 40.0389 46.0723 39.2506C45.3517 38.1781 44.7828 37.0109 44.3818 35.7821C44.2364 35.4176 44.181 35.0234 44.2202 34.6329C44.3407 34.1717 44.5971 33.7575 44.956 33.4445C46.2624 32.069 47.6586 30.6409 49.4094 30.2469C50.2448 30.1079 51.0943 30.0756 51.9378 30.1506C53.4047 30.1967 54.8731 30.1343 56.3308 29.9639C57.5319 29.8226 59.5616 29.8407 60.5799 29.0615C61.5286 28.3333 62.0633 27.419 63.2313 26.9559C64.4141 26.5167 65.6855 26.3711 66.9367 26.5316L66.9351 26.5314Z"
          fill="#2F2E41"
        />
        <path
          d="M222.106 201.095C228.797 201.095 234.22 200.176 234.22 199.042C234.22 197.909 228.797 196.99 222.106 196.99C215.416 196.99 209.992 197.909 209.992 199.042C209.992 200.176 215.416 201.095 222.106 201.095Z"
          fill="#57B894"
        />
        <path
          d="M227.116 195.686C227.668 195.234 228.071 194.625 228.27 193.939C228.421 193.248 228.126 192.428 227.462 192.161C226.721 191.877 225.928 192.394 225.329 192.913C224.731 193.431 224.04 194.026 223.254 193.914C223.658 193.548 223.962 193.083 224.133 192.564C224.305 192.045 224.34 191.49 224.234 190.954C224.199 190.732 224.104 190.523 223.96 190.35C223.55 189.91 222.802 190.097 222.312 190.447C220.745 191.548 220.308 193.683 220.299 195.598C220.14 194.907 220.275 194.186 220.269 193.486C220.263 192.785 220.073 191.975 219.474 191.606C219.104 191.407 218.688 191.309 218.269 191.322C217.564 191.295 216.78 191.367 216.299 191.883C215.696 192.523 215.859 193.597 216.377 194.297C216.895 194.998 217.687 195.447 218.417 195.933C218.999 196.282 219.496 196.757 219.87 197.325C219.915 197.404 219.951 197.488 219.978 197.575H224.38C225.372 197.072 226.292 196.437 227.115 195.687L227.116 195.686Z"
          fill="#57B894"
        />
        <path
          d="M57.4033 202C61.8185 202 65.3978 201.394 65.3978 200.645C65.3978 199.897 61.8185 199.291 57.4033 199.291C52.988 199.291 49.4087 199.897 49.4087 200.645C49.4087 201.394 52.988 202 57.4033 202Z"
          fill="#EFEFEF"
        />
        <path
          d="M60.7099 198.431C61.0742 198.132 61.3396 197.73 61.4712 197.278C61.5292 197.051 61.508 196.81 61.4111 196.597C61.3142 196.384 61.1474 196.21 60.9384 196.104C60.4492 195.917 59.9264 196.258 59.5309 196.6C59.1354 196.943 58.6799 197.335 58.161 197.262C58.4281 197.02 58.628 196.713 58.7412 196.37C58.8545 196.028 58.8772 195.662 58.8072 195.308C58.7839 195.161 58.7214 195.024 58.6263 194.91C58.357 194.619 57.8627 194.742 57.5388 194.973C56.505 195.701 56.2166 197.108 56.2107 198.373C56.1055 197.917 56.1948 197.441 56.1908 196.979C56.1868 196.517 56.0616 195.983 55.6658 195.738C55.4217 195.607 55.1474 195.543 54.8705 195.551C54.4052 195.533 53.8883 195.581 53.5701 195.922C53.1724 196.344 53.2799 197.053 53.6218 197.515C53.9637 197.977 54.4866 198.274 54.9679 198.594C55.3527 198.824 55.681 199.137 55.9283 199.511C55.9581 199.563 55.9821 199.619 55.9999 199.676H58.9047C59.5596 199.344 60.1668 198.925 60.7099 198.43V198.431Z"
          fill="#EFEFEF"
        />
        <path
          d="M12.1909 202C16.6061 202 20.1854 201.394 20.1854 200.645C20.1854 199.897 16.6061 199.291 12.1909 199.291C7.77558 199.291 4.19629 199.897 4.19629 200.645C4.19629 201.394 7.77558 202 12.1909 202Z"
          fill="#EFEFEF"
        />
        <path
          d="M15.4972 198.431C15.8615 198.132 16.127 197.73 16.2586 197.278C16.3166 197.051 16.2953 196.81 16.1984 196.597C16.1015 196.384 15.9348 196.21 15.7258 196.104C15.2366 195.917 14.7137 196.258 14.3182 196.6C13.9227 196.943 13.4672 197.335 12.9484 197.262C13.2154 197.02 13.4153 196.713 13.5286 196.37C13.6418 196.028 13.6645 195.662 13.5945 195.308C13.5713 195.161 13.5087 195.024 13.4136 194.91C13.1444 194.619 12.6501 194.742 12.3262 194.973C11.2923 195.701 11.004 197.108 10.9981 198.373C10.8928 197.917 10.9822 197.441 10.9781 196.979C10.9741 196.517 10.8489 195.983 10.4532 195.738C10.209 195.607 9.93474 195.543 9.65783 195.551C9.1926 195.533 8.67569 195.581 8.35745 195.922C7.9598 196.344 8.06721 197.053 8.40913 197.515C8.75106 197.977 9.27391 198.274 9.75529 198.594C10.1401 198.824 10.4683 199.137 10.7156 199.511C10.7454 199.563 10.7695 199.619 10.7873 199.676H13.692C14.347 199.344 14.9542 198.925 15.4972 198.43V198.431Z"
          fill="#EFEFEF"
        />
        <path d="M243 201.227H0V201.718H243V201.227Z" fill="#2F2E41" />
        <path
          d="M231.138 13.6268C231.138 13.6268 205.771 12.0935 208.76 29.9003C208.76 29.9003 208.157 33.0481 211.013 34.4756C211.013 34.4756 211.059 33.1568 213.616 33.6064C214.527 33.7575 215.452 33.8021 216.373 33.7391C217.615 33.6572 218.797 33.1731 219.741 32.3598C219.741 32.3598 226.872 29.4081 229.644 17.7342C229.644 17.7342 231.696 15.1871 231.614 14.532L227.333 16.3639C227.333 16.3639 228.798 19.4574 227.635 22.0277C227.635 22.0277 227.496 16.4744 226.674 16.5953C226.508 16.6195 224.45 17.6666 224.45 17.6666C224.45 17.6666 226.966 23.0537 225.068 26.9715C225.068 26.9715 225.788 20.3318 223.664 18.056L220.651 19.8186C220.651 19.8186 223.592 25.384 221.597 29.9262C221.597 29.9262 222.109 22.9604 220.015 20.2471L217.286 22.3808C217.286 22.3808 220.049 27.8645 218.365 31.6318C218.365 31.6318 218.145 23.5252 216.695 22.9127C216.695 22.9127 214.306 25.0253 213.942 25.8884C213.942 25.8884 215.833 29.8722 214.659 31.9727C214.659 31.9727 213.939 26.5705 213.348 26.5403C213.348 26.5403 210.968 30.1196 210.721 32.5763C210.721 32.5763 210.823 28.9274 212.772 26.2023C212.772 26.2023 210.471 26.5977 209.127 28.0903C209.127 28.0903 209.497 25.5612 213.36 25.3379C213.36 25.3379 215.327 22.6216 215.851 22.4587C215.851 22.4587 212.013 22.1351 209.684 23.171C209.684 23.171 211.733 20.7838 216.556 21.8702L219.253 19.664C219.253 19.664 214.197 18.9727 212.052 19.7366C212.052 19.7366 214.52 17.6239 219.979 19.1632L222.913 17.4066C222.913 17.4066 218.602 16.4769 216.035 16.8029C216.035 16.8029 218.747 15.3392 223.775 16.9267L225.884 15.9828C225.884 15.9828 222.727 15.3611 221.802 15.2616C220.876 15.162 220.829 14.9085 220.829 14.9085C222.829 14.5744 224.882 14.8033 226.761 15.5696C226.761 15.5696 231.214 13.9076 231.138 13.6268Z"
          fill="#57B894"
        />
        <path
          d="M196.382 4.19943C196.382 4.19943 184.933 3.50817 186.271 11.5544C186.271 11.5544 186.002 12.976 187.289 13.6217C187.289 13.6217 187.31 13.0181 188.467 13.2263C188.878 13.2956 189.295 13.3159 189.711 13.2867C190.273 13.2495 190.807 13.029 191.232 12.6589C191.232 12.6589 194.456 11.3249 195.709 6.04937C195.709 6.04937 196.634 4.8996 196.598 4.60373L194.664 5.43065C194.664 5.43065 195.323 6.82802 194.805 7.98992C194.805 7.98992 194.742 5.48163 194.371 5.53935C194.296 5.53935 193.365 6.02482 193.365 6.02482C193.365 6.02482 194.504 8.4603 193.645 10.229C193.645 10.229 193.971 7.22908 193.01 6.19987L191.648 7.00899C191.648 7.00899 192.977 9.52295 192.076 11.5754C192.076 11.5754 192.308 8.42766 191.362 7.20237L190.129 8.1682C190.129 8.1682 191.377 10.646 190.618 12.3487C190.618 12.3487 190.515 8.68469 189.861 8.40716C189.861 8.40716 188.783 9.36086 188.617 9.75328C188.617 9.75328 189.473 11.5641 188.942 12.5027C188.942 12.5027 188.617 10.061 188.34 10.0483C188.34 10.0483 187.264 11.6666 187.153 12.7646C187.207 11.7403 187.527 10.7479 188.081 9.88544C187.46 10.0032 186.887 10.3001 186.433 10.7396C186.433 10.7396 186.602 9.59577 188.346 9.49625C188.346 9.49625 189.25 8.268 189.473 8.19248C189.473 8.19248 187.738 8.04764 186.686 8.51613C186.686 8.51613 187.611 7.43864 189.789 7.92762L190.994 6.93159C190.994 6.93159 188.708 6.62681 187.739 6.96287C187.739 6.96287 188.854 6.00918 191.321 6.70341L192.647 5.90966C192.647 5.90966 190.698 5.49026 189.538 5.63995C189.538 5.63995 190.764 4.97889 193.036 5.69389L193.985 5.26829C193.985 5.26829 192.558 4.98752 192.138 4.94221C191.718 4.8969 191.698 4.78227 191.698 4.78227C192.602 4.63155 193.531 4.73606 194.379 5.08408C194.379 5.08408 196.417 4.32512 196.382 4.19943Z"
          fill="#3F3D56"
        />
        <path
          d="M19.8349 127.035C19.8349 127.035 8.38663 126.344 9.72418 134.39C9.72418 134.39 9.45494 135.811 10.7424 136.457C10.7424 136.457 10.7634 135.854 11.9203 136.062C12.3312 136.131 12.7488 136.151 13.1644 136.122C13.7264 136.085 14.2607 135.864 14.6858 135.494C14.6858 135.494 17.9094 134.16 19.1626 128.885C19.1626 128.885 20.0875 127.735 20.0511 127.439L18.1169 128.266C18.1169 128.266 18.7768 129.663 18.2586 130.825C18.2586 130.825 18.1953 128.317 17.8248 128.375C17.7494 128.375 16.8184 128.86 16.8184 128.86C16.8184 128.86 17.9573 131.296 17.0987 133.064C17.0987 133.064 17.4239 130.065 16.463 129.035L15.1013 129.844C15.1013 129.844 16.4299 132.358 15.5291 134.411C15.5291 134.411 15.7612 131.263 14.8151 130.038L13.5828 131.004C13.5828 131.004 14.8302 133.481 14.0709 135.184C14.0709 135.184 13.9686 131.52 13.3147 131.243C13.3147 131.243 12.2361 132.196 12.0703 132.589C12.0703 132.589 12.9259 134.4 12.3958 135.338C12.3958 135.338 12.0703 132.896 11.7932 132.884C11.7932 132.884 10.7176 134.502 10.6062 135.6C10.66 134.576 10.9799 133.583 11.5342 132.721C10.9135 132.839 10.3408 133.136 9.88625 133.575C9.88625 133.575 10.0551 132.431 11.7994 132.332C11.7994 132.332 12.7032 131.103 12.9262 131.028C12.9262 131.028 11.1909 130.883 10.1393 131.352C10.1393 131.352 11.0641 130.274 13.2425 130.763L14.4476 129.767C14.4476 129.767 12.1618 129.462 11.1926 129.798C11.1926 129.798 12.3072 128.845 14.7747 129.539L16.1004 128.745C16.1004 128.745 14.1512 128.326 12.9913 128.475C12.9913 128.475 14.2174 127.814 16.4892 128.529L17.4382 128.104C17.4382 128.104 16.0113 127.823 15.5913 127.778C15.1713 127.732 15.1513 127.618 15.1513 127.618C16.0557 127.467 16.9842 127.572 17.8326 127.92C17.8326 127.92 19.8702 127.161 19.8349 127.035Z"
          fill="#3F3D56"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="56.365"
          y1="103.622"
          x2="56.365"
          y2="103.544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.251" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="243" height="202" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InviteSuccess;
