import React from "react";

const LoginImg = () => {
    return (
        <svg width="290" height="250" viewBox="0 0 290 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="145" cy="226.562" rx="145" ry="23.4375" fill="#C7CAF5" />
            <path d="M63.1239 149.639L64.1194 151.775C64.1194 151.775 69.77 159.606 66.2793 160.833C62.7886 162.06 60.9363 153.406 60.9363 153.406L60.0134 152.194L63.1239 149.639Z" fill="#A0616A" />
            <path d="M56.6141 109.186L57.543 108.778C57.543 108.778 58.6422 109.186 58.6422 110.941C58.6422 112.696 57.2902 138.319 57.2902 138.319L59.9942 152.359L56.2761 153.061L50.53 140.074L56.6141 109.186Z" fill="#575A89" />
            <path d="M56.6141 109.186L57.543 108.778C57.543 108.778 58.6422 109.186 58.6422 110.941C58.6422 112.696 57.2902 138.319 57.2902 138.319L64.5573 150.428L59.8252 153.938L50.53 140.074L56.6141 109.186Z" fill="#575A89" />
            <path d="M55.9386 149.901C55.9386 149.901 60.3327 151.656 59.9947 154.815C59.6567 157.974 55.2626 220.802 55.2626 220.802L56.6146 227.822L43.7704 228.173L43.4324 182.895L34.3062 228.173H21.4619L30.5881 148.497L55.9386 149.901Z" fill="#2F2E41" />
            <path d="M54.9249 226.419L57.9669 229.578C57.9669 229.578 65.0651 230.982 63.375 233.439C61.685 235.896 44.1086 232.737 44.1086 232.737L44.4467 227.121L54.9249 226.419Z" fill="#2F2E41" />
            <path d="M32.9542 226.419L35.9962 229.578C35.9962 229.578 43.0944 230.982 41.4043 233.439C39.7143 235.896 22.1379 232.737 22.1379 232.737L22.4759 227.121L32.9542 226.419Z" fill="#2F2E41" />
            <path d="M45.1224 96.5509C49.6026 96.5509 53.2346 92.7794 53.2346 88.127C53.2346 83.4746 49.6026 79.7031 45.1224 79.7031C40.6422 79.7031 37.0103 83.4746 37.0103 88.127C37.0103 92.7794 40.6422 96.5509 45.1224 96.5509Z" fill="#A0616A" />
            <path d="M50.1924 93.041L49.5163 107.432L39.0378 101.114L40.39 93.041H50.1924Z" fill="#A0616A" />
            <path d="M38.7002 97.6035L48.5025 104.975L50.1926 99.7094L53.2346 108.133L51.5445 123.226L38.7002 97.6035Z" fill="#5575E5" />
            <path d="M39.7507 96.5649L49.5161 111.996L49.9078 99.0947L51.1105 100.079C51.7724 100.621 52.3074 101.311 52.6752 102.099C53.0431 102.886 53.2342 103.75 53.2342 104.625L57.9663 109.188L56.2762 126.738L55.9382 147.447C55.9382 147.447 57.9663 149.202 57.2903 150.255C56.6143 151.308 53.9102 153.764 50.8681 153.764C47.8261 153.764 28.8977 151.308 28.8977 149.904C28.8977 148.5 29.5737 130.248 29.5737 130.248V105.678L37.6859 99.3602L39.7507 96.5649Z" fill="#575A89" />
            <path d="M28.2217 154.817L32.6158 161.486L34.3056 153.764L32.2776 152.36L28.2217 154.817Z" fill="#A0616A" />
            <path opacity="0.2" d="M49.5372 164.873L43.0393 183.15L43.6736 183.394L50.1715 165.116L49.5372 164.873Z" fill="black" />
            <path d="M40.2211 79.8795C40.2211 79.8795 41.2351 74.6146 46.9812 75.6676L46.3052 76.0185C46.3052 76.0185 51.7133 75.3166 51.0373 78.4755C51.0373 78.4755 55.4314 81.2835 54.4174 84.4424C53.8211 86.2733 53.3689 88.1515 53.0653 90.0584L52.0513 89.0054C52.0513 89.0054 51.0373 81.9855 44.9532 82.6875C40.3901 83.214 38.531 85.4515 38.0557 87.0639C37.8369 87.8221 37.5575 88.5599 37.2206 89.2699L36.841 89.3564C36.841 89.3564 34.813 81.6345 40.2211 79.8795Z" fill="#2F2E41" />
            <path d="M33.2916 105.326L30.2146 105.177L29.5735 105.677C28.8377 106.44 28.1597 107.262 27.5455 108.134C26.8695 109.186 18.0813 134.809 18.0813 134.809C17.9182 136.365 18.1513 137.939 18.7573 139.372C19.7713 141.829 27.8835 156.571 27.8835 156.571L33.9676 152.71L25.8555 135.511L31.6016 127.087L33.2916 105.326Z" fill="#575A89" />
            <path d="M213.169 53.7549H211.973V19.7064C211.973 17.1185 211.482 14.5559 210.528 12.1651C209.574 9.77416 208.176 7.60175 206.414 5.77184C204.652 3.94194 202.56 2.49038 200.258 1.50004C197.955 0.509708 195.487 -7.43177e-06 192.995 0H123.528C121.036 -7.86894e-06 118.569 0.509708 116.266 1.50004C113.964 2.49038 111.872 3.94194 110.11 5.77184C108.347 7.60175 106.95 9.77416 105.996 12.1651C105.042 14.5559 104.551 17.1185 104.551 19.7064V206.499C104.551 209.087 105.042 211.649 105.996 214.04C106.95 216.431 108.347 218.604 110.11 220.434C111.872 222.264 113.964 223.715 116.266 224.705C118.569 225.696 121.036 226.205 123.528 226.205H192.995C195.487 226.205 197.955 225.696 200.258 224.705C202.56 223.715 204.652 222.264 206.414 220.434C208.176 218.604 209.574 216.431 210.528 214.04C211.482 211.649 211.973 209.087 211.973 206.499V77.9912H213.169V53.7549Z" fill="#3F3D56" />
            <path d="M208.104 18.3875V207.82C208.103 211.783 206.587 215.583 203.888 218.386C201.19 221.188 197.53 222.763 193.713 222.763H122.822C119.005 222.764 115.343 221.19 112.644 218.387C109.944 215.585 108.426 211.784 108.425 207.82V18.3875C108.426 14.4235 109.944 10.6222 112.644 7.81982C115.343 5.01741 119.005 3.44335 122.822 3.44385H131.422C130.999 4.52218 130.839 5.69171 130.954 6.84964C131.069 8.00758 131.456 9.11846 132.081 10.0846C132.706 11.0508 133.55 11.8428 134.539 12.3908C135.528 12.9388 136.632 13.2262 137.753 13.2277H178.17C179.291 13.2262 180.395 12.9389 181.384 12.3908C182.373 11.8428 183.217 11.0509 183.842 10.0847C184.468 9.11848 184.855 8.00759 184.97 6.84965C185.085 5.69171 184.924 4.52218 184.502 3.44385H193.713C197.53 3.44465 201.19 5.01932 203.888 7.82163C206.587 10.6239 208.103 14.4245 208.104 18.3875V18.3875Z" fill="#E6E6E6" />
            <path d="M194.024 117.035H122.505V191.302H194.024V117.035Z" fill="white" />
            <path d="M178.637 83.5483H138.296V86.697H178.637V83.5483Z" fill="white" />
            <path d="M183.668 91.731H132.899V94.8796H183.668V91.731Z" fill="white" />
            <path d="M170.953 124.204H143.113V126.377H170.953V124.204Z" fill="#E6E6E6" />
            <path d="M186.104 130.583H143.113V132.755H186.104V130.583Z" fill="#5575E5" />
            <path d="M178.597 136.659H143.561V138.832H178.597V136.659Z" fill="#E6E6E6" />
            <path d="M163.567 143.324H143.113V145.497H163.567V143.324Z" fill="#E6E6E6" />
            <path d="M173.983 149.835H143.113V152.008H173.983V149.835Z" fill="#5575E5" />
            <path d="M133.834 123.521H130.425V127.061H133.834V123.521Z" fill="#E6E6E6" />
            <path d="M133.834 129.901H130.425V133.441H133.834V129.901Z" fill="#5575E5" />
            <path d="M133.834 135.975H130.425V139.515H133.834V135.975Z" fill="#E6E6E6" />
            <path d="M133.834 142.641H130.425V146.181H133.834V142.641Z" fill="#E6E6E6" />
            <path d="M133.834 149.151H130.425V152.691H133.834V149.151Z" fill="#5575E5" />
            <path d="M170.953 156.387H143.113V158.56H170.953V156.387Z" fill="#E6E6E6" />
            <path d="M186.104 162.767H143.113V164.94H186.104V162.767Z" fill="#5575E5" />
            <path d="M178.597 168.842H143.561V171.015H178.597V168.842Z" fill="#E6E6E6" />
            <path d="M163.567 175.506H143.113V177.679H163.567V175.506Z" fill="#E6E6E6" />
            <path d="M173.983 182.018H143.113V184.191H173.983V182.018Z" fill="#E6E6E6" />
            <path d="M133.834 155.703H130.425V159.243H133.834V155.703Z" fill="#E6E6E6" />
            <path d="M133.834 162.083H130.425V165.622H133.834V162.083Z" fill="#5575E5" />
            <path d="M133.834 168.157H130.425V171.697H133.834V168.157Z" fill="#E6E6E6" />
            <path d="M133.834 174.824H130.425V178.364H133.834V174.824Z" fill="#E6E6E6" />
            <path d="M133.834 181.334H130.425V184.874H133.834V181.334Z" fill="#E6E6E6" />
            <path d="M158.264 74.0789C171.142 74.0789 181.582 63.2381 181.582 49.8654C181.582 36.4926 171.142 25.6519 158.264 25.6519C145.386 25.6519 134.947 36.4926 134.947 49.8654C134.947 63.2381 145.386 74.0789 158.264 74.0789Z" fill="white" />
            <path d="M158.072 37.9922C155.798 37.9922 153.576 38.6922 151.686 40.0038C149.795 41.3154 148.322 43.1796 147.452 45.3607C146.582 47.5418 146.354 49.9418 146.798 52.2572C147.242 54.5727 148.336 56.6995 149.944 58.3689C151.551 60.0382 153.6 61.175 155.829 61.6356C158.059 62.0962 160.37 61.8598 162.471 60.9563C164.571 60.0529 166.366 58.523 167.629 56.5601C168.892 54.5971 169.566 52.2894 169.566 49.9286C169.558 46.7657 168.344 43.735 166.19 41.4985C164.036 39.262 161.118 38.0014 158.072 37.9922V37.9922ZM158.072 41.5731C158.754 41.5731 159.421 41.7831 159.988 42.1766C160.555 42.5701 160.997 43.1293 161.258 43.7837C161.519 44.438 161.587 45.158 161.454 45.8526C161.321 46.5472 160.992 47.1853 160.51 47.6861C160.028 48.1869 159.413 48.5279 158.745 48.6661C158.076 48.8043 157.382 48.7334 156.752 48.4623C156.122 48.1913 155.583 47.7323 155.205 47.1434C154.826 46.5546 154.623 45.8622 154.623 45.154C154.626 44.2053 154.991 43.2963 155.637 42.6254C156.283 41.9545 157.158 41.5763 158.072 41.5731V41.5731ZM158.072 58.812C156.71 58.8055 155.369 58.454 154.168 57.788C152.966 57.122 151.939 56.1616 151.175 54.99C151.23 52.6028 155.773 51.2886 158.072 51.2886C160.371 51.2886 164.913 52.6028 164.969 54.99C164.204 56.1607 163.177 57.1204 161.975 57.7864C160.774 58.4523 159.434 58.8043 158.072 58.812Z" fill="#5575E5" />
            <path d="M221.552 146.834L218.144 146.22C218.144 146.22 211.179 142.882 210.757 145.41C210.335 147.938 216.982 150.177 216.982 150.177L224.135 149.382L221.552 146.834Z" fill="#FFB8B8" />
            <path d="M241.374 121.24L236.6 143.815L220.664 146.152L220.052 152.812L240.604 152.869L250.883 133.368L241.374 121.24Z" fill="#575A89" />
            <path opacity="0.2" d="M241.374 121.24L236.6 143.815L220.664 146.152L220.052 152.812L240.604 152.869L250.883 133.368L241.374 121.24Z" fill="black" />
            <path d="M217.116 127.787L214.684 125.233C214.684 125.233 210.878 118.317 209.121 120.121C207.364 121.925 211.525 127.754 211.525 127.754L217.795 131.419L217.116 127.787Z" fill="#FFB8B8" />
            <path d="M250.619 114.102L253.613 113.345C254.756 113.346 255.884 113.084 256.917 112.577C257.95 112.07 258.862 111.331 259.586 110.413C260.31 109.495 260.828 108.421 261.104 107.27C261.379 106.119 261.404 104.918 261.178 103.755V103.755C261.182 100.98 260.123 98.3179 258.236 96.3534C256.349 94.3889 253.788 93.2833 251.116 93.2798H251.116C248.444 93.2763 245.88 94.3752 243.988 96.3348C242.096 98.2944 241.032 100.954 241.028 103.729V103.729C240.641 105.128 240.614 106.609 240.951 108.022C241.288 109.436 241.977 110.733 242.948 111.783C243.919 112.833 245.139 113.6 246.485 114.007C247.831 114.415 249.257 114.447 250.619 114.102V114.102Z" fill="#2F2E41" />
            <path d="M233.01 165.915L235.447 193.626L239.861 220.827L247.272 220.836L246.316 194.154L242.403 161.823L233.01 165.915Z" fill="#2F2E41" />
            <path d="M240.85 219.289L235.905 222.361C235.905 222.361 227.998 223.377 229.478 225.944C230.957 228.512 248.253 226.995 248.254 225.969C248.255 224.942 247.768 219.298 246.78 219.296L240.85 219.289Z" fill="#2F2E41" />
            <path d="M254.888 163.521C254.888 163.521 254.749 167.483 251.283 173.636L251.754 192.621L269.514 216.247L263.083 221.883L241.373 196.199L235.975 165.406L242.901 158.231L254.888 163.521Z" fill="#2F2E41" />
            <path d="M268.031 216.246C268.031 216.246 273.959 217.28 272.968 219.844C271.976 222.409 269.492 234.207 263.07 232.659C263.07 232.659 260.105 232.655 261.096 230.604C261.691 229.37 262.353 228.172 263.077 227.015L263.085 220.344L268.031 216.246Z" fill="#2F2E41" />
            <path d="M247.411 114.623C251.504 114.623 254.822 111.177 254.822 106.926C254.822 102.675 251.504 99.2295 247.411 99.2295C243.317 99.2295 239.999 102.675 239.999 106.926C239.999 111.177 243.317 114.623 247.411 114.623Z" fill="#FFB8B8" />
            <path d="M250.863 112.061L250.852 120.784L242.946 120.774L243.945 112.052L250.863 112.061Z" fill="#FFB8B8" />
            <path d="M255.736 166.971L250.831 166.119C244.574 165.027 238.166 165.307 232.02 166.942L234.238 144.008L234.527 141.019L235.145 134.617C235.145 134.617 233.007 126.182 236.146 123.332C239.977 119.853 243.368 117.083 243.368 117.083L250.857 117.706C255.044 126.375 254.137 137.27 251.312 149.008L255.736 166.971Z" fill="#575A89" />
            <path d="M247.561 118.896L231.055 134.401L216.771 126.698L213.772 130.509L226.389 142.053C227.256 142.699 228.275 143.089 229.338 143.184C230.401 143.279 231.468 143.074 232.428 142.592L248.524 134.503L247.561 118.896Z" fill="#575A89" />
            <path d="M261.718 101.256C264.359 101.256 266.5 99.0324 266.5 96.2899C266.5 93.5474 264.359 91.3242 261.718 91.3242C259.077 91.3242 256.936 93.5474 256.936 96.2899C256.936 99.0324 259.077 101.256 261.718 101.256Z" fill="#2F2E41" />
            <path d="M257.305 93.6204C257.306 92.8859 257.463 92.1606 257.766 91.497C258.07 90.8333 258.511 90.2478 259.058 89.7827C259.606 89.3176 260.246 88.9845 260.932 88.8074C261.619 88.6303 262.335 88.6136 263.028 88.7585C262.416 88.4583 261.75 88.2964 261.073 88.2835C260.397 88.2707 259.725 88.4072 259.103 88.684C258.481 88.9608 257.923 89.3716 257.465 89.8893C257.008 90.4069 256.661 91.0195 256.448 91.6867C256.236 92.3538 256.162 93.0603 256.232 93.7592C256.302 94.4582 256.514 95.1338 256.854 95.7412C257.194 96.3487 257.655 96.8743 258.205 97.2831C258.756 97.6919 259.383 97.9747 260.047 98.1128C259.227 97.7114 258.533 97.0758 258.047 96.2803C257.562 95.4848 257.304 94.5622 257.305 93.6204V93.6204Z" fill="#2F2E41" />
            <path d="M253.203 96.4377L246.756 95.2051L241.798 98.4319C240.579 99.2251 239.605 100.365 238.991 101.716C238.377 103.067 238.149 104.572 238.334 106.053L243.299 104.746L244.411 101.851C245.125 103.063 246.122 104.068 247.309 104.773C250.123 106.45 250.775 110.015 251.108 113.859L256.547 110.787L260.012 105.148L253.203 96.4377Z" fill="#2F2E41" />
            <path opacity="0.2" d="M247.113 129.843L234.239 144.008L234.527 141.018L247.113 129.843Z" fill="black" />
        </svg>
    );
};

export default LoginImg;