import React from "react";
import SnackBar from "../../components/Snackbar";
import {Divider} from "@material-ui/core";
import Form from "./Form";
import "../../assets/css/common.css";
import SubmitButton from "../../components/SubmitButton";
import {Button} from "@material-ui/core";
import moment from "moment";
import {color} from "../../config.js";
import {MdDelete} from "react-icons/md";

const InviteDesign = ({
  invitees,
  snackbar: {visible, message, variant},
  showLabel,
  handleSubmit,
  handleChange,
  handleDelete,
  onClose,
  condoName,
  visiting_time,
}) => {
  return (
    <React.Fragment>
      <div className="head-content-title">
        <p style={{margin: "5px 0px", color: color}}>Hello Contractor</p>
      </div>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="form-container">
          <p
            className="custom-text"
            style={{
              margin: 15,
              textAlign: "center",
              color: color,
            }}
          >
            {`Please fill the form below to generate your entry pass at ${condoName} 
            on ${moment(visiting_time).format("DD-MM-YYYY ddd hh:mm A")}`}
          </p>
          {visible && (
            <SnackBar
              triggerOpen={visible}
              message={message}
              variant={variant}
              onClose={onClose}
            />
          )}
          {invitees.map((invitee, index) => {
            return (
              <React.Fragment>
                {showLabel && (
                  <div
                    className="responsive-div"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      className="custom-text"
                      style={{
                        margin: "15px 15px 15px 0px",
                        color: "#6A6A6E",
                        textAlign: "start",
                      }}
                    >
                      Contractor: {index + 1}
                    </p>

                    {index !== 0 && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleDelete(index);
                        }}
                        style={{
                          backgroundColor: "transparent",
                          borderRadius: "10px",
                          padding: "5px 0px",
                          boxShadow: "none",
                        }}
                      >
                        <MdDelete
                          style={{color: "#f50057", fontSize: "30px"}}
                        />
                      </Button>
                    )}
                  </div>
                )}
                <Form
                  defaultInvitee={index === 0}
                  onChange={(e) => handleChange(e, index)}
                  handleDelete={() => handleDelete(index)}
                  values={invitee}
                />
                <Divider style={{width: "100%", margin: "20px 0px"}} />
              </React.Fragment>
            );
          })}
        </div>
        <SubmitButton loading={false} />
      </form>
    </React.Fragment>
  );
};

export default InviteDesign;
