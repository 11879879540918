import React from "react";
import { SimpleTextField } from "../../components/FormField";

const Form = (props) => {
  const { onChange, values, defaultInvitee } = props;
  const { name, phone, nric, vehicle_number } = values;
  return (
    <React.Fragment>
      <SimpleTextField
        id="name"
        label="Name"
        defaultValue={name}
        onChange={onChange}
        required
      />
      <SimpleTextField
        value={phone}
        onChange={onChange}
        id="phone"
        label="Phone"
        defaultValue={phone}
        required
        disabled={defaultInvitee}
      />
      <SimpleTextField
        value={nric}
        onChange={onChange}
        id="nric"
        label="NRIC/FIN Number"
        inputProps={{
          maxLength: 9,
        }}
        required
      />
      <SimpleTextField
        value={vehicle_number}
        onChange={onChange}
        id="vehicle_number"
        label="Vehicle Number"
        required
      />
    </React.Fragment>
  );
};

export default Form;
