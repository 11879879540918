import React from 'react';
import Form from './views/Subscribe/Form';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Invites from './components/Invite';
import QRInvitations from './views/Invitation';
import Announcement from './views/Announcement';
import Webcheckin from './views/Webcheck/index';
import Contractor from './views/Webcheck/Contractor';
import Delivery from './views/Webcheck/Delivery';
import PickupDrop from './views/Webcheck/PickupDrop';
import Visitor from './views/Webcheck/Visitor';
import Contractors from './views/Contractor';
import { alias_name } from './config';
import { fetchCondoDetails } from './api/condo';
import { setCookie, getCookie } from './helpers';
import { Home } from './components/HomePage';
import { searchUnitData } from './api/visit';
import PageNotFound from './components/ErrorPage';
import Invitation from './views/webInvite/invitation';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      condoName: '',
      location: {},
      loader: true,
      aliasName: '',
      errorPage: false,
      blockList: [],
    };
  }

  componentDidMount = () => {
    // let body = alias_name;
    // let title = body && body.charAt(0).toUpperCase() + body.slice(1);
    // document.getElementById('title').text = `${title} Services`;
    // fetchCondoDetails(body)
    //   .then(({ data }) => {
    //     setCookie('condo_name', data.name, 60);
    //     this.setState({
    //       condoName: data.name,
    //       location: data.location,
    //       loader: false,
    //       aliasName: data.alias_name,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     this.setState({ errorPage: true, loader: false });
    //   });
  };

  // fetchBlock = () => {
  //   searchUnitData('', getCookie('vms_token'))
  //     .then(({ data }) => {
  //       let units = data.map((value) => ({
  //         label: value.unit_number,
  //         value: value.unit_number,
  //       }));
  //       this.setState({ blockList: units, loader: false });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       this.setState({ loader: false, errorPage: true });
  //     });
  // };

  render() {
    const {
      condoName,
      location,
      loader,
      aliasName,
      errorPage,
      blockList,
    } = this.state;
    const about = { condoName: 'Ardmore Park' };

    // if (loader) {
    //   return <div id="loader" />;
    // }

    if (errorPage) {
      return <PageNotFound condos={about} />;
    }
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {/* <Route
              path="/residents/subscribe"
              render={(props) => <Form {...props} about={about} />}
              exact
            />
            <Route
              path="/invitations/contractors/:invite_id/update"
              render={(props) => <Invites {...props} about={about} />}
              exact
            />

            <Route
              path="/invitations/new"
              render={(props) => (
                <Invitation
                  {...props}
                  condos={about}
                  subHeading={'Contrator Registration'}
                />
              )}
              exact
            />

            <Route
              path="/invitations/:invitee_id"
              render={(props) => <QRInvitations {...props} about={about} />}
              exact
            /> */}

            <Route
              path="/contractor/:id"
              render={(props) => <Contractors {...props} about={about} />}
              exact
            />

            {/* <Route
              path="/announcements/:id/:status"
              render={(props) => (
                <Announcement
                  {...props}
                  token={new URL(window.location.href).searchParams.get(
                    'token'
                  )}
                  about={about}
                />
              )}
              exact
            />
            <Route
              path="/webcheckin/contractor"
              render={(props) => {
                let query = new URL(window.location.href).searchParams.get(
                  'token'
                );
                if (query) {
                  setCookie('vms_token', query, 60);
                  this.fetchBlock();
                  return <Redirect to={`/webcheckin/contractor`} />;
                } else {
                  return <Contractor {...props} about={about} />;
                }
              }}
              exact
            />
            <Route
              path="/webcheckin/delivery"
              render={(props) => {
                let query = new URL(window.location.href).searchParams.get(
                  'token'
                );
                if (query) {
                  setCookie('vms_token', query, 60);
                  this.fetchBlock();
                  return <Redirect to={`/webcheckin/delivery`} />;
                } else {
                  return <Delivery {...props} about={about} />;
                }
              }}
              exact
            />
            <Route
              path="/webcheckin/pickupdrop"
              render={(props) => {
                let query = new URL(window.location.href).searchParams.get(
                  'token'
                );
                if (query) {
                  setCookie('vms_token', query, 60);
                  this.fetchBlock();
                  return <Redirect to={`/webcheckin/pickupdrop`} />;
                } else {
                  return <PickupDrop {...props} about={about} />;
                }
              }}
              exact
            />
            <Route
              path="/webcheckin/visitor"
              render={(props) => {
                let query = new URL(window.location.href).searchParams.get(
                  'token'
                );
                if (query) {
                  setCookie('vms_token', query, 60);
                  this.fetchBlock();
                  return <Redirect to={`/webcheckin/visitor`} />;
                } else {
                  return <Visitor {...props} about={about} />;
                }
              }}
              exact
            />
            <Route
              exact
              path="/webcheckin"
              render={(props) => {
                let query = new URL(window.location.href).searchParams.get(
                  'token'
                );
                if (query) {
                  setCookie('vms_token', query, 60);
                  this.fetchBlock();
                  return <Redirect to={`/webcheckin`} />;
                } else {
                  return <Webcheckin {...props} about={about} />;
                }
              }}
            />*/}
            <Route
              path="/home"
              render={(props) => <Home {...props} condos={about} />}
            />
            <Route to="*">
              <Redirect to="/home" />
            </Route>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
