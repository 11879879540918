import React from 'react';
import Head from '../../components/Head';

const ResponsePage = (props) => {
  return (
    <React.Fragment>
      <Head
        condo={props.condoName || 'Building Management System'}
        subHeading={'Contrator Registration'}
      />
      <div className="qr-root" style={{ backgroundColor: '#ffffff' }}>
        <div className="qr-container">
          <div className="qr-innercontainer">
            <img
              alt="sucess"
              className="timer-icon"
              style={{ width: '100%', height: '100%', margin: '10px' }}
              src={require('../../assets/img/visitimage.svg')}
            />
          </div>
          <div className="qr-innercontainer1">
            <p className="content-title">
              Sorry...!!! This Invite is already updated.
            </p>
            <br />
            <p className="content-title">
              Ask the sender to generate to new invite.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResponsePage;
