import React from "react";
import { SimpleTextField, SimplePhoneField } from "../../components/FormField";
import { MdDelete } from "react-icons/md";
import { Button, Divider } from "@material-ui/core";

const VisitorCard = (props) => {
  const { onChange, values, index, handleDelete, onBlur } = props;
  const { name, phone, vehicle_number, error } = values;
  const dataPresent = !!name || !!phone || !!vehicle_number
  const required = index === 0 || dataPresent
  return (
    <React.Fragment>
      <Divider style={{ width: "80%", margin: "20px 0px" }} />
      <div
        className="responsive-div"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p
          className="custom-text"
          style={{
            margin: "0px 15px 15px 0px",
            color: "#6A6A6E",
            textAlign: "start",
          }}
        >
          Visitor: {index + 1} <span>{!required && index !== 0 && "(optional)"}</span>
        </p>

        {dataPresent && (
          <Button
            variant="contained"
            onClick={() => {
              handleDelete(index);
            }}
            style={{
              backgroundColor: "transparent",
              borderRadius: "10px",
              padding: "5px 0px",
              boxShadow: "none",
            }}
          >
            <MdDelete
              style={{ color: "#f50057", fontSize: "30px" }}
            />
          </Button>
        )}
      </div>
      <SimpleTextField
        id="name"
        label="Name"
        value={name}
        onChange={onChange}
        required={required}
        helperText=" "
      />
      <SimplePhoneField
        id="phone"
        label="Phone"
        value={phone}
        onChange={onChange}
        required={required}
        error={error}
        helperText={error ? "Invalid Phone Number" : " "}
        onBlur={onBlur}
      />
      <SimpleTextField
        id="vehicle_number"
        label="Vehicle Number"
        value={vehicle_number}
        onChange={onChange}
        required={required}
      />
    </React.Fragment>
  );
};

export default VisitorCard;
