import React, { Component } from "react";
import { updateAnnouncements } from "../api/resident";
import "../assets/css/common.css";
import Head from "../components/Head";

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      message: "",
    };
  }

  componentDidMount = () => {
    let id = this.props.match.params.id;
    let requestBody = {
      announcements: {
        status: this.props.match.params.status,
      },
    };
    let token = this.props.token;
    updateAnnouncements(id, requestBody, token)
      .then(() => {
        this.setState({ loader: false });
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  render() {
    const { loader } = this.state;
    if (loader) {
      return <div id="loader" />;
    }
    return (
      <React.Fragment>
        <Head
          condo={this.props.about.condoName}
          subHeading={"Announcement Confirmation"}
        />
        <div className="main-root">
          <div className="main-container">
            <img
              alt="sucess"
              width="60%"
              height="70%"
              style={{ margin: "50px 20px" }}
              src={require("../assets/img/successAnnounce.svg")}
            />
            <p
              className="custom-text"
              style={{ fontSize: 24, color: "#6258e7" }}
            >
              Announcement Sent Successfully...!!!
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Announcement;
