import makeRequest from ".";

export function fetchDetails({ block, floor, unit, phone }) {
  return makeRequest({
    uri: `/api/v1/residents/details?&block=${block}&floor=${floor}&unit=${unit}&phone=${phone}`,
    method: "GET",
  });
}

export function updateNotification(id, body) {
  return makeRequest({
    uri: `/api/v1/residents/${id}/subscribe`,
    method: "PUT",
    body: JSON.stringify(body),
  });
}

export function updateAnnouncements(id, requestBody, token) {
  return makeRequest({
    uri: `/api/v1/announcements/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
    authorization: token
  });
}
