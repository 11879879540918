import React, { Component } from 'react';
import { InputAdornment } from '@material-ui/core';
import {
  Head,
  SimpleTextField,
  DateTimeField,
  SnackBar,
} from '../../components';
import SubmitButton from '../../components/SubmitButton';
import { Calender } from '../../assets/icons';
import VisitorCard from './visitorCard';
import { Guest, Contractor, Delivery } from '../../assets/icons/purpose';

const styles = {
  text: {
    fontFamily: '"Montserrat"',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
  },
  input: {
    width: 300,
    marginBottom: 15,
  },
};

const CalendarIcon = () => {
  return (
    <InputAdornment position="end">
      <Calender />
    </InputAdornment>
  );
};

class InvitePage extends Component {
  constructor(props) {
    super(props);
    this.purposes = [
      {
        img: <Guest />,
        name: 'Guest',
        value: 'guest',
      },
      {
        img: <Contractor />,
        name: 'Contractor',
        value: 'contractor',
      },
      {
        img: <Delivery />,
        name: 'Delivery',
        value: 'delivery',
      },
    ];
    this.visitor = {
      name: '',
      phone: '',
      vehicle_number: '',
      error: false,
    };

    this.state = {
      data: {
        description: '',
        visiting_time: null,
        purpose: 'guest',
        invitees: [{ ...this.visitor }],
      },
      validation: {
        dateErrorText: '',
        validDate: true,
      },
    };
  }

  validateAndSaveDate = (dateErrorText, validDate, visiting_time) => {
    const { data, validation } = this.state;

    this.setState({
      data: { ...data, visiting_time },
      validation: { ...validation, dateErrorText, validDate },
    });
  };

  handlePurposeChange = (value) => {
    const { data } = this.state;

    this.setState({ data: { ...data, purpose: value } });
  };

  handleInputChange = (event) => {
    const { data } = this.state;

    this.setState({ data: { ...data, [event.target.id]: event.target.value } });
  };

  handleInviteeChange = (type, event, index) => {
    const { data } = this.state;
    const invitees = JSON.parse(JSON.stringify(data.invitees));

    if (type === 'add') {
      invitees[index][event.target.id] = event.target.value;
    } else if (type === 'delete') {
      invitees[index] = this.visitor;
    }

    let empty_obj = invitees.filter((item) => {
      return !item.name && !item.phone && !item.vehicle_number;
    });

    let empty_card_index = invitees
      .filter((invitee) => {
        return !invitee.name && !invitee.phone && !invitee.vehicle_number;
      })
      .map((card) => invitees.indexOf(card))
      .filter((i) => i !== index);

    if (
      empty_card_index.length === 0 &&
      index < 4 &&
      invitees.length < 5 &&
      empty_obj.length === 0
    ) {
      //Add empty card
      invitees[invitees.length] = this.visitor;
    } else if (empty_card_index.length > 1 || empty_obj.length > 1) {
      //Reduce card when empty card is >1
      empty_card_index.forEach((i) => {
        return invitees.splice(i, 1);
      });
    }

    this.setState({ data: { ...data, invitees } });
  };

  onBlur = (index) => {
    const { data } = this.state;
    const invitees = JSON.parse(JSON.stringify(data.invitees));

    const phone = invitees[index].phone;
    if (phone) {
      let phoneRegex = /^[0-9]{8,10}\d*$/;
      if (!phoneRegex.test(phone)) {
        invitees[index]['error'] = true;
      } else {
        invitees[index]['error'] = false;
      }
    }

    this.setState({ data: { ...data, invitees } });
  };

  handleSubmit = () => {
    const {
      data,
      validation: { validDate },
    } = this.state;

    if (!data.visiting_time || !validDate) {
      const validation = { ...this.state.validation };
      validation.validDate = false;
      this.setState({ validation });
      return;
    }

    const invitees = JSON.parse(JSON.stringify(data.invitees));

    if (invitees.filter((invitee) => invitee.error).length !== 0) {
      return;
    }

    let new_invitees = invitees.filter((invitee) => {
      return invitee.name && invitee.phone && invitee.vehicle_number;
    });

    let invites = { ...data, invitees: new_invitees };

    this.props.formSubmit(invites);
  };

  render() {
    const { visiting_time, description, purpose, invitees } = this.state.data;

    let {
      validateAndSaveDate,
      handleInputChange,
      handlePurposeChange,
      handleInviteeChange,
      handleSubmit,
      onBlur,
    } = this;

    const {
      condos,
      snackbar: { visible, variant, message },
      onClose,
      disableBtn,
    } = this.props;

    return (
      <React.Fragment>
        <Head condo={condos.condoName} subHeading={'Contrator Registration'} />
        <div className="main-root">
          <div className="main-container">
            <div style={{ textAlign: 'center', width: '100%' }}>
              <p className="para-head">Enter the visitor details</p>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'center',
                  marginBottom: '15px',
                }}
              >
                {this.purposes.map((purposeVal, i) => (
                  <div
                    key={i}
                    className="invite-icon"
                    style={{
                      border:
                        purposeVal.value === purpose
                          ? '1px solid #5575e5'
                          : '1px solid lightgray',
                    }}
                    onClick={() => handlePurposeChange(purposeVal.value)}
                  >
                    {purposeVal.img}
                    <p style={{ marginTop: 10, marginBottom: 5 }}>
                      {purposeVal.name}
                    </p>
                  </div>
                ))}
              </div>

              <div className="form-container">
                {visible && (
                  <SnackBar
                    triggerOpen={visible}
                    message={message}
                    variant={variant}
                    onClose={onClose}
                  />
                )}
                <DateTimeField
                  label={'Visiting Time'}
                  value={visiting_time}
                  InputProps={{
                    endAdornment: CalendarIcon(),
                    style: { ...styles.text, textAlign: 'center' },
                  }}
                  className="a1-date-picker"
                  helperText={'Please Select Date and Time'}
                  onChange={validateAndSaveDate}
                  error={this.state.validation.validDate === false}
                />
                <SimpleTextField
                  id="description"
                  label={'Description'}
                  defaultValue={description}
                  multiline
                  onChange={handleInputChange}
                  style={{
                    ...styles.input,
                    maxWidth: '100%',
                    width: 616,
                    textAlign: 'right',
                  }}
                  inputProps={{
                    style: { ...styles.text, minHeight: 100 },
                  }}
                />
                <div className="side-heading">
                  <p style={{ marginBottom: 0, fontSize: 24 }}>
                    Visitor Details
                  </p>
                </div>
                {invitees.map((invitee, index) => {
                  return (
                    <VisitorCard
                      key={index}
                      values={invitee}
                      index={index}
                      handleDelete={(e) =>
                        handleInviteeChange('delete', e, index)
                      }
                      onChange={(e) => handleInviteeChange('add', e, index)}
                      onBlur={() => onBlur(index)}
                    />
                  );
                })}
              </div>
              <SubmitButton loading={disableBtn} />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default InvitePage;
