import React, { Component } from 'react';
import Head from '../../components/Head';
import '../../assets/css/common.css';
import SubmitButton from '../../components/SubmitButton';
import SnackBar from '../../components/Snackbar';
import { OTPImg } from '../../assets/icons';

const Otp = ({ name, value, tabIndex, onChange, onKeyUp }) => {
  return (
    <input
      required
      name={name}
      type="number"
      autoComplete="off"
      className="otp-input"
      value={value}
      onChange={onChange}
      tabIndex={tabIndex}
      maxLength="1"
      onKeyUp={onKeyUp}
    />
  );
};

class InviteOTP extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      seconds: 30,
      allowRetry: false,
      resendDisable: false,
    };
  }

  handleFormSubmit = () => {
    const { otp1, otp2, otp3, otp4 } = this.state;
    let otp = `${otp1}${otp2}${otp3}${otp4}`;

    this.props.formSubmit(otp);
  };

  componentDidMount = () => {
    this.retryCounter();
  };

  retryCounter = () => {
    this.timer = setInterval(() => {
      const { seconds } = this.state;
      if (seconds > 1) {
        this.setState({ seconds: seconds - 1 });
      } else {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
          this.setState({ seconds: 0, allowRetry: true });
        }
      }
    }, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  handleChange(value, event) {
    this.setState({ [value]: event.target.value });
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  resendOtp = () => {
    const { resendDisable } = this.state;
    const { sendOtp, phone } = this.props;

    if (!resendDisable) {
      this.setState({ resendDisable: true });
      sendOtp('', phone);
    }
  };

  render() {
    const {
      condos,
      phone,
      disableBtn,
      onClose,
      snackbar: { visible, message, variant },
    } = this.props;
    const {
      otp1,
      otp2,
      otp3,
      otp4,
      seconds,
      allowRetry,
      resendDisable,
    } = this.state;

    return (
      <React.Fragment>
        <Head condo={condos.condoName} subHeading={'Contrator Registration'} />
        <div className="main-root">
          <div className="main-container">
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <p style={{ fontSize: '18px' }}>Please enter the OTP code</p>
            </div>
            <OTPImg />
            <div style={{ textAlign: 'center', width: '100%' }}>
              <p style={{ color: '#aca9a9', fontSize: '18px' }}>
                Enter the OTP code sent to +65 - {phone}
              </p>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.handleFormSubmit();
              }}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div className="form-container" style={{ margin: '5% 0%' }}>
                <Otp
                  name="otp1"
                  value={otp1}
                  tabIndex="1"
                  onChange={(e) => this.handleChange('otp1', e)}
                  onKeyUp={this.inputfocus}
                />
                <Otp
                  name="otp2"
                  value={otp2}
                  tabIndex="2"
                  onChange={(e) => this.handleChange('otp2', e)}
                  onKeyUp={this.inputfocus}
                />
                <Otp
                  name="otp3"
                  value={otp3}
                  tabIndex="3"
                  onChange={(e) => this.handleChange('otp3', e)}
                  onKeyUp={this.inputfocus}
                />
                <Otp
                  name="otp4"
                  value={otp4}
                  tabIndex="4"
                  onChange={(e) => this.handleChange('otp4', e)}
                  onKeyUp={this.inputfocus}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  display: 'inline-flex',
                  margin: '10px 0px',
                  justifyContent: 'space-between',
                }}
              >
                <p style={{ marginLeft: 10 }}>Didn't receive OTP yet? </p>
                {allowRetry ? (
                  <p
                    style={{
                      textDecoration: 'underline',
                      color: resendDisable ? 'gray' : '#1661d3',
                      marginRight: '10px',
                      cursor: resendDisable ? 'none' : 'pointer',
                      border: 'none',
                      outline: 'none',
                      backgroundColor: 'transparent',
                    }}
                    onClick={this.resendOtp}
                  >
                    RESEND OTP
                  </p>
                ) : (
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      textAlign: 'end',
                      marginRight: '10px',
                    }}
                  >
                    {seconds < 10 ? `00 : 0${seconds}` : `00 : ${seconds}`}
                  </p>
                )}
              </div>
              {visible && (
                <SnackBar
                  triggerOpen
                  message={message}
                  variant={variant}
                  onClose={onClose}
                />
              )}
              <SubmitButton text="VERIFY" loading={disableBtn} />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default InviteOTP;
