import React, { Component } from "react";
import "../../assets/css/common.css";
import { SimpleTextField, CustomFormControl } from "../../components/FormField";
import SubmitButton from "../../components/SubmitButton";
import { getCookie } from "../../helpers";
import { createVisit, searchUnitData } from "../../api/visit";
import SuccessPage from "../../components/SuccessPage";
import Asynchronous from "../../components/AutoComplete";
import { DeliverySuccess } from "../../components/SuccessIcons";
import { DeliveryHead } from "../../components/IndexIcon";
import workflow from "../../workflow.json";
import Head from "../../components/Head";
import PageNotFound from "../../components/ErrorPage";

class Delivery extends Component {
  constructor(props) {
    super();
    this.state = {
      loader: true,
      message: "",
      data: {
        phone: "",
        type: "",
        block: "",
        number_plate: "",
        mode_of_entry: "car",
        temperature: "",
      },
      blockList: [],
      success: false,
      errorMessage: false,
      loading: false,
      error: false,
      homePage: false,
      temperatureShow:
        workflow[`${props.about.aliasName}`]["fields"]["temperature"],
    };
  }

  handleChange = (name, value) => {
    const data = { ...this.state.data };
    let { error } = this.state;
    if (name === "phone") {
      let phoneno = /^[0-9]{7,8}\d*$/;
      if (value.match(phoneno)) {
        data[name] = value;
        error = false;
      } else {
        data[name] = value;
        error = true;
      }
    } else {
      data[name] = value;
    }

    this.setState({ data, error }, () => {
      this.state.data.block && this.setState({ errorMessage: false });
    });
  };

  componentDidMount = () => {
    const { blockList } = this.props.about;
    !blockList.length
      ? searchUnitData("", getCookie("vms_token"))
          .then(({ data }) => {
            let units = data.map((value) => ({
              label: value.unit_number,
              value: value.unit_number,
            }));
            this.setState({ blockList: units, loader: false });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loader: true, homePage: true });
          })
      : this.setState({ blockList: blockList, loader: false });
  };

  requestParams = () => {
    const { data } = this.state;
    return {
      visits: {
        ...data,
        visitor_type: "delivery",
        mode_of_entry:
          data.mode_of_entry === "bike" ? "car" : data.mode_of_entry,
      },
    };
  };

  formSubmit = () => {
    if (this.state.data.phone.length !== 8) {
      return document.getElementById("phone").focus();
    }
    this.setState({ loading: true });
    createVisit(this.requestParams(), getCookie("vms_token"))
      .then(() => {
        this.setState({ loading: false, success: true });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  render() {
    const styles = {
      text: {
        fontStyle: "normal",
        fontSize: "18px",
        lineHeight: "22px",
      },
      input: {
        width: 300,
        marginBottom: 15,
      },
    };
    const { handleChange } = this;
    const types = [
      "Grab food",
      "Food panda",
      "Deliveroo",
      "Mcdonalds",
      "Redmart",
      "NinjaVan",
      "Fed Ex",
      "UPS",
      "Zalora",
      "Urban Fo",
      "Lala Move",
      "NTUC",
      "CJ Logistic",
      "DHL",
      "Amazon",
      "Q-xpress",
      "Gaint",
      "IKEA",
      "JNT",
      "EMART",
      "Forty Two",
      "Aramex",
      "Others",
    ];
    const {
      data: { phone, mode_of_entry, number_plate, type, temperature },
      loader,
      success,
      errorMessage,
      blockList,
      loading,
      error,
      homePage,
      temperatureShow,
    } = this.state;

    if (homePage) {
      return <PageNotFound />;
    }

    if (success) {
      return (
        <SuccessPage
          content={
            workflow[`${this.props.about.aliasName}`]["delivery"][
              "success_message"
            ]
          }
          head={workflow[`${this.props.about.aliasName}`]["delivery"]["title"]}
          compo={<DeliverySuccess />}
          subHeading={"Visitor Self-Checkin Service"}
          condos={this.props.about}
        />
      );
    }
    if (loader) {
      return <div id="loader" />;
    }
    return (
      <React.Fragment>
        <Head
          condo={this.props.about.condoName}
          subHeading={"Visitor Self-Checkin Service"}
        />
        <div className="main-root">
          <div className="main-container">
            <div className="head-content-title">
              <p style={{ margin: "15px 0px 5px" }}>Hello there,</p>
              <p style={{ margin: "5px 0px 15px" }}>Kindly fill your details</p>
            </div>
            <DeliveryHead />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.formSubmit();
              }}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="form-container">
                <SimpleTextField
                  id="phone"
                  label="Phone"
                  defaultValue={phone}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  inputProps={{
                    maxLength: 8,
                    inputMode: "number",
                    pattern: "[0-9]*",
                  }}
                  error={error}
                  required
                  className="text-field"
                />
                {temperatureShow && (
                  <SimpleTextField
                    id="temperature"
                    label="Temperature"
                    defaultValue={temperature}
                    onChange={(e) =>
                      handleChange("temperature", e.target.value)
                    }
                    required
                    className="text-field"
                  />
                )}
                <CustomFormControl
                  required
                  variant="outlined"
                  className={"text-field"}
                  style={{ ...styles.input, ...styles.text }}
                >
                  <Asynchronous
                    label="Type"
                    name="type"
                    value={type}
                    handleChange={(e) => handleChange("type", e.target.value)}
                    dataPromise={types
                      .sort()
                      .map((x) => ({ label: x, value: x }))}
                  />
                </CustomFormControl>
                <CustomFormControl
                  required
                  variant="outlined"
                  className={"text-field"}
                  style={{ ...styles.input, ...styles.text }}
                >
                  <Asynchronous
                    label="Block"
                    name="block"
                    handleChange={(e) => handleChange("block", e.target.value)}
                    dataPromise={blockList}
                    required
                  />
                </CustomFormControl>
                <p
                  className="custom-text"
                  style={{ margin: "", textAlign: "start" }}
                >
                  Vehicle Details :
                </p>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="car"
                    name="car"
                    onClick={(e) => {
                      handleChange("mode_of_entry", e.target.id);
                    }}
                    checked={mode_of_entry === "car" ? true : false}
                  />
                  <label className="label" for="car">
                    Car/M-Bike
                  </label>
                  <input
                    type="radio"
                    id="escooter"
                    name="selector"
                    onClick={(e) => {
                      handleChange("mode_of_entry", e.target.id);
                    }}
                    checked={mode_of_entry === "escooter" ? true : false}
                  />
                  <label className="label" for="escooter" name="escooter">
                    Bicycle
                  </label>
                  <input
                    type="radio"
                    id="walkin"
                    name="selector"
                    onChange={(e) => {
                      handleChange("mode_of_entry", e.target.id);
                    }}
                    checked={mode_of_entry === "walkin" ? true : false}
                  />
                  <label className="label" for="walkin">
                    Walk In
                  </label>
                </div>

                {(mode_of_entry === "car" || mode_of_entry === "bike") && (
                  <SimpleTextField
                    id="number_plate"
                    label="Number Plate"
                    defaultValue={number_plate}
                    onChange={(e) =>
                      handleChange("number_plate", e.target.value)
                    }
                    required
                  />
                )}
              </div>
              {errorMessage && (
                <span
                  style={{
                    margin: "15px auto 10px",
                    color: "#DB3B44",
                  }}
                >
                  Please fill all fields
                </span>
              )}
              <SubmitButton text="SUBMIT" loading={loading} />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Delivery;
