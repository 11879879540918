import React, { useState } from 'react';
import Head from '../../components/Head';
import { SimpleTextField, SimplePhoneField } from '../../components/FormField';
import SubmitButton from '../../components/SubmitButton';
import { LoginImg } from '../../assets/icons';
import SnackBar from '../../components/Snackbar';

const ResidentForm = (props) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(false);

  const {
    condos,
    disableBtn,
    onClose,
    formSubmit,
    snackbar: { visible, message, variant },
  } = props;

  const handleFormSubmit = () => {
    if (!error) {
      formSubmit(name, phone);
    }
  };

  const onPhoneUpdate = (e) => {
    setPhone(e.target.value);
    setError(false);
  };

  const onBlur = () => {
    let phoneRegex = /^[0-9]{8,10}\d*$/;
    if (phone && !phoneRegex.test(phone)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <React.Fragment>
      <Head condo={condos.condoName} subHeading="Contrator Registration" />
      <div className="main-root">
        <div className="main-container">
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <p style={{ fontSize: '18px' }}>
              Enter the registered phone number for your unit
            </p>
          </div>
          <LoginImg />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
            }}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className="form-container" style={{ marginTop: '5%' }}>
              <SimpleTextField
                id="name"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                helperText=" "
                required
              />
              <SimplePhoneField
                id="phone"
                label="Phone"
                value={phone}
                onChange={onPhoneUpdate}
                error={error}
                helperText={error ? 'Invalid Phone Number' : ' '}
                onBlur={onBlur}
                required
              />
              {visible && (
                <SnackBar
                  triggerOpen
                  message={message}
                  variant={variant}
                  onClose={onClose}
                />
              )}
            </div>
            <SubmitButton text="VALIDATE" loading={disableBtn} />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResidentForm;
