import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SuccessPage from '../../components/SuccessPage';
import { color } from '../../config';
import InviteDesign from './InviteDesign';
import { fetchInviteDetails } from '../../api/invite';
import SnackBar from '../../components/Snackbar';
import Head from '../../components/Head';
class Invite extends Component {
  constructor(props) {
    super(props);
    this.handleRef = React.createRef();
    this.invite_id = '';
  }

  componentDidMount() {
    this.invite_id = this.props.match.params.invite_id;
    fetchInviteDetails(this.invite_id)
      .then(({ data }) => {
        this.setState({
          data,
          visible: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const {
      condoName,
      submitted,
      visiting_time,
      invitees,
      snackbar,
    } = this.props.data;
    const { handleSubmit, handleChange, handleDelete, onClose } = this.props;
    if (submitted) {
      return (
        <React.Fragment>
          {snackbar.visible && (
            <SnackBar
              triggerOpen={snackbar.visible}
              message={snackbar.message}
              variant={snackbar.variant}
              onClose={onClose}
            />
          )}
          <SuccessPage
            image="successed.png"
            content={['Thank you for submitting your details.']}
            data={
              <p className="content-title" style={{ color: color }}>
                Click <a href={`/invitations/${this.invite_id}`}>here</a> to get
                the QR Pass.
              </p>
            }
            textColor={color}
          />
        </React.Fragment>
      );
    }

    const showLabel = invitees && invitees.length > 1;
    const InviteDesignProps = {
      invitees,
      showLabel,
      handleSubmit,
      handleChange,
      handleDelete,
      onClose,
      snackbar,
      condoName,
      visiting_time,
    };

    return (
      <React.Fragment>
        <Head
          condo={this.props.condos.condoName}
          subHeading={'Contrator Registration'}
        />
        <div className="main-root">
          <div className="main-container">
            <InviteDesign {...InviteDesignProps} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Invite);
