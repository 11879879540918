import React from "react";
import { Button } from "@material-ui/core";
import "../assets/css/common.css";
import { color } from "../config";

const SubmitButton = ({ onClick, loading, text, btnColor }) => (
  <Button
    className="button"
    variant="contained"
    type="submit"
    onClick={onClick}
    disabled={loading}
    style={{ backgroundColor: btnColor || color }}
  >
    {text || "SUBMIT"}
  </Button>
);
export default SubmitButton;
