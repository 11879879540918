import { getCookie } from './helpers';
const name = (alias_name) => {
  try {
    return alias_name.split('.')[0].split('-')[1];
  } catch {
    return alias_name.split('-')[1];
  }
};

export const alias_name = name(window.location.host);
export const color = '#5575e6';
export const success = require('./assets/img/success.png');
export const apiHost = `https://api-${name(
  window.location.href
)}.katomaran.tech`;

export const devModeEnabled = getCookie('dev_mode');
