import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import Invited from "./invited";
import SuccessPage from "../../components/SuccessPage";
import {fetchInviteDetails} from "../../api/invite";
import {color} from "../../config";
import PageNotFound from "../../components/ErrorPage";
import ResponsePage from "./responsePage";

class QRGen extends Component {
  constructor(props) {
    super(props);
    this.handleRef = React.createRef();
    this.timer = null;
    this.invitee_id = props.match.params.invitee_id;
    this.state = {
      inviteTrue: null,
      data: {},
      visible: false,
      errorPage: false,
      showQR: false,
    };
  }

  componentDidMount() {
    this.fetchInviteData();
  }

  showQRPage = () => {
    this.setState({showQR: true});
    this.timer = setInterval(() => {
      if (!this.state.inviteTrue) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        return;
      }
      this.fetchInviteData();
    }, 10000);
  };

  fetchInviteData = () => {
    fetchInviteDetails(this.invitee_id)
      .then(({data}) => {
        this.computeStatus(data);
        this.setState({
          data,
          visible: true,
        });
      })
      .catch((err) => {
        this.setState({errorPage: true});
        console.log(err);
      });
  };

  computeStatus = (data) => {
    let inviteeStatus = data.invite && data.invite.status;
    if (data.status === "invited") {
      if (
        inviteeStatus === "invited" ||
        inviteeStatus === "partially_visited" ||
        inviteeStatus === "expired"
      ) {
        this.setState({inviteTrue: true});
      } else if (inviteeStatus === "blocked" || inviteeStatus === "cancelled") {
        this.setState({inviteFalse: true, inviteTrue: false});
      } else if (inviteeStatus === "created") {
        this.setState({redirectInvite: true, inviteTrue: false});
      } else if (inviteeStatus === "visited") {
        this.setState({
          redirectInvite: true,
          inviteTrue: false,
          inviteFalse: false,
        });
        this.showQRPage();
      }
    } else if (data.status === "created") {
      this.setState({
        redirectInvite: true,
        inviteTrue: false,
        inviteFalse: false,
      });
    } else {
      this.setState({inviteFalse: true, inviteTrue: false});
    }
  };

  render() {
    const {
      data,
      visible,
      errorPage,
      inviteTrue,
      inviteFalse,
      redirectInvite,
      showQR,
    } = this.state;

    const {about} = this.props;

    if (errorPage) {
      return <PageNotFound />;
    }

    if (!visible) {
      return <div id="loader" />;
    }

    if (inviteTrue) {
      return (
        <Invited
          data={data}
          condo={about}
          enabled={showQR}
          showQRPage={this.showQRPage}
        />
      );
    }

    if (data.status === "visited" || data.invite.status === "visited") {
      return <ResponsePage {...about} />;
    }

    if (inviteFalse) {
      return (
        <SuccessPage
          image={"cancelled.png"}
          textColor={color}
          content={
            data.invite.status === "cancelled"
              ? ["Invite Cancelled ...!!!"]
              : data.invite.status === "blocked"
              ? ["Invite Blocked..Contact Resident"]
              : ["Sorry...!!! This Invite is already updated."]
          }
        />
      );
    }

    if (redirectInvite) {
      return (
        <Redirect to={`/invitations/contractors/${this.invite_id}/update`} />
      );
    }

    return <div style={{minHeight: "91.6vh"}}></div>;
  }
}
export default QRGen;
