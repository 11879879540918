import React, { Component } from "react";
import "../../assets/css/common.css";
import { Button } from "@material-ui/core";
import {
  VisitorIcon,
  PickupIcon,
  DeliveryIcon,
  ContractorIcon,
} from "../../components/IndexIcon";
import Head from "../../components/Head";

class Announcement extends Component {
  constructor() {
    super();
    this.state = {
      loader: true,
      message: "",
    };
  }

  onClick = (e) => {
    this.props.history.push(`/webcheckin/${e}`);
  };

  render() {
    const styles = {
      borderRadius: "12px",
      border: "1px solid lightgray",
      padding: "0px 3px",
    };
    return (
      <React.Fragment>
        <Head
          condo={this.props.about.condoName}
          subHeading={"Visitor Self-Checkin Service"}
        />
        <div className="main-root">
          <div className="main-container">
            <div className="content-title">
              <p className="webindex-sub-title" style={{ margin: "10px 0px" }}>
                Please Select your Visitor Type
              </p>
            </div>
            <div className="form-container">
              <Button
                className="button-with-img"
                variant="contained"
                type="submit"
                name="visitor"
                onClick={() => {
                  this.onClick("visitor");
                }}
              >
                <div style={{ ...styles }}>
                  <VisitorIcon />
                  <p className="web-index-para">Visitor</p>
                </div>
              </Button>
              <Button
                className="button-with-img"
                variant="contained"
                type="submit"
                name="contractor"
                onClick={(e) => {
                  this.onClick("contractor");
                }}
              >
                <div style={{ ...styles }}>
                  <ContractorIcon />
                  <p className="web-index-para">Contractor</p>
                </div>
              </Button>
              <Button
                className="button-with-img"
                variant="contained"
                type="submit"
                onClick={() => {
                  this.onClick("delivery");
                }}
              >
                <div style={{ ...styles }}>
                  <DeliveryIcon />
                  <p className="web-index-para">Delivery</p>
                </div>
              </Button>

              <Button
                className="button-with-img"
                variant="contained"
                type="submit"
                onClick={() => {
                  this.onClick("pickupdrop");
                }}
                id="pickupdrop"
              >
                <div style={{ ...styles }}>
                  <PickupIcon />
                  <p className="web-index-para">Pickup/Drop</p>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Announcement;
