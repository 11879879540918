import React from 'react';
import { SimpleTextField } from '../../components/FormField';
import SubmitButton from '../../components/SubmitButton';
import '../../assets/css/common.css';

const FormDesign = ({
  block,
  floor,
  unit,
  fetchResidentData,
  handleChange,
  visible,
  phone,
  loading,
  message,
}) => {
  return (
    <React.Fragment>
      <div className="main-root" style={{ backgroundColor: '#ffffff' }}>
        <div className="main-container" style={{ backgroundColor: '#ffffff' }}>
          <form
            onSubmit={(e) => {
              fetchResidentData(e);
            }}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className="form-container">
              <SimpleTextField
                name="block"
                value={block}
                label="Block"
                onChange={handleChange}
                required
              />
              <SimpleTextField
                name="floor"
                value={floor}
                label={'Floor'}
                onChange={handleChange}
                required
              />
              <SimpleTextField
                name="unit"
                value={unit}
                label={'Unit'}
                onChange={handleChange}
                required
              />
              <SimpleTextField
                name="phone"
                value={phone}
                label={'Phone'}
                onChange={handleChange}
                required
              />
            </div>

            <SubmitButton text="SUBMIT" loading={loading} />
            {visible && (
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#ff471a',
                }}
              >
                {message ===
                'Primary Resident only can subscribe the notification'
                  ? message
                  : 'Incorrect Details'}
              </p>
            )}
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FormDesign;
