import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import Head from 'components/Head';
import { fetchContractorDetails } from 'api/contractor';
import PageNotFound from 'components/ErrorPage';
import Expired from 'components/ExpiredPage';
import moment from 'moment';

class Contractor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expired: false,
      showQR: true,
      err: 0,
    };
  }
  componentDidMount() {
    this.fetchContractorData(this.props.match.params.id);
  }

  fetchContractorData = (id) => {
    fetchContractorDetails(id)
      .then((data) => {
        if (!data?.status) {
          this.setState({ showQR: false, expired: true });
        }
        this.setState({ data: data?.data });
      })
      .catch((e) => {
        console.log(e);
        let page = true;
        if (e[0] === 404 || e[0] === 401) {
          page = false;
        }
        this.setState({
          showQR: page,
          e: e[0],
        });
      });
  };

  render() {
    const { match, about } = this.props;
    const { id } = match.params;
    const { showQR, expired, data, e } = this.state;
    if (expired) {
      return (
        <React.Fragment>
          <Head
            condo={about?.condoName || 'Condo'}
            subHeading={'Contrator Registration'}
          />
          <div
            className="qr-root"
            style={{ backgroundColor: '#ffffff', justifyContent: 'end' }}
          >
            <Expired />
          </div>
        </React.Fragment>
      );
    }
    if (showQR) {
      return (
        <React.Fragment>
          <Head
            condo={about?.condoName || 'Condo'}
            subHeading={'Contrator Registration'}
          />
          <div
            className="qr-root"
            style={{ backgroundColor: '#ffffff', justifyContent: 'end' }}
          >
            {showQR && (
              <div className="qr-container">
                <div className="qr-innercontainer">
                  <QRCode
                    value={`{"id":"${id}","type":"contractor"}`}
                    renderAs="svg"
                    size={280}
                    style={{
                      padding: '20px 10px',
                    }}
                  />
                </div>
                <p
                  style={{
                    textAlign: 'center',
                    margin: '0px',
                    padding: '20px 10px',
                  }}
                >
                  Hi {data?.name} Kindly scan this code in the estate’s entry
                  gate
                  <br /> to generate your entry pass
                </p>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    }
    if (!showQR) {
      return (
        <React.Fragment>
          <Head
            condo={about?.condoName || 'Condo'}
            subHeading={'Contrator Registration'}
          />
          <div
            className="qr-root"
            style={{ backgroundColor: '#ffffff', justifyContent: 'end' }}
          >
            <PageNotFound />
          </div>
        </React.Fragment>
      );
    }
  }
}
export default Contractor;
