import makeRequest from ".";

export function createVisit(requestBody, token) {
     return makeRequest({
          uri: `/api/v1/visits`,
          method: "POST",
          body: JSON.stringify(requestBody),
          authorization: token
     });
}

export function searchUnitData(search = "", token) {
     let value = search && search.split("#");
     return makeRequest({
          uri: `/api/v1/units/search_units?query=${value[1] ? `${value[0]}-${value[1]}` : value[0] ? value[0] : ""
               }`,
          authorization: token
     });
}