import React from "react";
import Head from "./Head";
import { HomeIcon } from "./IndexIcon";
import { color } from "../config";

export const Home = ({ condos, subHeading }) => {
  return (
    <React.Fragment>
      <Head
        condo={condos.condoName}
        subHeading={subHeading || "Building Management System"}
      />
      <div className="main-root" style={{paddingTop:"5%"}}>
        <div className="main-container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <HomeIcon style={{ marginTop: "20px" }} />
          </div>

          <h1
            style={{ textAlign: "center", color: color, marginTop: "50px" }}
          >{`Welcome to the ${condos.condoName || "Smart Condo"} `}</h1>
        </div>
      </div>
    </React.Fragment>
  );
};
