import React from "react";
import "../assets/css/common.css";
import Head from "./Head";

const SuccessPage = ({
  content,
  image,
  head,
  textColor,
  data,
  compo,
  condos,
  subHeading,
  imgComponent,
}) => {
  return (
    <React.Fragment>
      <Head
        condo={(condos && condos.condoName) || ""}
        subHeading={subHeading || "Building Management System"}
      />
      <div className="main-root">
        <div className="main-container">
          <header className="header">
            <h2 style={{textAlign: "center", margin: "0px"}}>{head || ""}</h2>
          </header>
          {imgComponent && imgComponent}
          {image && (
            <img
              alt="sucess"
              className="head-icon"
              style={{marginBottom: 0}}
              src={require("../assets/img/" + image)}
            />
          )}
          {compo && compo}
          {content &&
            content.map((msg) => (
              <p className="content-title" style={{color: textColor}}>
                {" "}
                {msg}{" "}
              </p>
            ))}
          {data && data}
        </div>
      </div>
    </React.Fragment>
  );
};
export default SuccessPage;
